import { AppBar, Toolbar, Typography } from "@mui/material";
import React from "react";
import { TopBarProps } from "./TopBar";

const TopBar: React.FC<TopBarProps> = ({ title, right }) => {
	return (
		<AppBar
			position="fixed"
			sx={{
				width: `calc(100% - ${250}px)`,
				ml: `${250}px`,
			}}
		>
			<Toolbar sx={{ justifyContent: "space-between" }}>
				<Typography variant="h6" noWrap component="div">
					{title}
				</Typography>
				{right && right}
			</Toolbar>
		</AppBar>
	);
};

export { TopBar };
