import React from "react";
import "./style.css";

const PageNotFound = () => {
	return (
		<div className="mainPNF">
			<h1>404</h1>
			<h2>Página não encontrada</h2>
		</div>
	);
};

export default PageNotFound;
