import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { ClientProps } from "../QRCode";
import { useContext, useState } from "react";
import ClientContext from "../../../contexts/client";
import api from "../../../services/api";

interface RefundProps {
  client: ClientProps | undefined;
  accessToken: string;
  setSuccess: (value: boolean) => void;
  setSuccessText: (value: string) => void;
  setError: (value: string) => void;
}

export function Refund({ client, accessToken, setSuccess, setSuccessText, setError }: RefundProps) {
  const { addClient } = useContext(ClientContext);
  const [refundLoading, setRefundLoading] = useState(false);
  const [showConfirmationRefund, setShowConfirmationRefund] = useState(false);

  const handleRefund = () => {
    setShowConfirmationRefund(false);
    setRefundLoading(true);
    addClient({
      accessToken,
    });
    api.post(`/client/${client?.id}/refund`)
      .then((res) => {
        const { message } = res.data.data;
        setRefundLoading(false);
        setSuccess(true);
        setSuccessText(message);
      }
      )
      .catch((err) => {
        setRefundLoading(false);
        setError(
          err.response?.data?.message || "Algo de errado ocorreu"
        );
      }
      );
  };
  return (
    <>
      <Box display="flex">
        <Button
          onClick={() => setShowConfirmationRefund(true)}
          variant="outlined"
          size="small"
          color="inherit"
          sx={{ mt: 2 }}
        >
          {refundLoading ? (
            <>
              <CircularProgress size={18} />
              <Typography ml={1} fontSize={12}>Solicitar devolução</Typography>
            </>
          ) : "Solicitar devolução"}

        </Button>
      </Box>

      <Dialog
        open={showConfirmationRefund}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-refund"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmação da devolução.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-refund">
            {`Tem certeza que deseja estornar seu saldo?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmationRefund(false)}>
            Não
          </Button>
          <Button onClick={() => handleRefund()}>Sim</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}