import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
} from "chart.js";
import { IBarChart, IChartData } from "./BarChart";
import { formatCurrency } from "../../../utils/formatter";
import { Box, CircularProgress } from "@mui/material";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const options: ChartOptions<"bar"> = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
	},
	scales: {
		y: {
			ticks: {
				callback: function (value, index, ticks) {
					return formatCurrency(
						typeof value === "string" ? parseFloat(value) : value
					);
				},
			},
		},
		x: {
			grid: {
				display: false,
			},
		},
	},
};

const BarChart: React.FC<IBarChart> = ({ data, loading }) => {
	const [chartData, setChartData] = useState<IChartData>({
		labels: [],
		datasets: [],
	});
	const barRef = useRef<ChartJS<"bar", number[], string>>(null);

	useEffect(() => {
		if (data) {
			buildData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const buildData = () => {
		let valueArr: number[] = [];
		let labelArr: string[] = [];

		const chart = barRef.current;
		data.map((item) => {
			labelArr.push(item.label);
			valueArr.push(item.value);
			return true;
		});

		setChartData({
			labels: labelArr,
			datasets: [
				{
					label: "",
					data: valueArr,
					backgroundColor: chart
						? createGradient(chart.ctx)
						: "#dd4291",
				},
			],
		});
	};

	const createGradient = (ctx: CanvasRenderingContext2D) => {
		const colorStart = "#dd4291cc";
		const colorEnd = "#ffbbdecc";

		const gradient = ctx.createLinearGradient(100, 0, 100, 400);

		gradient.addColorStop(0, colorStart);
		gradient.addColorStop(1, colorEnd);

		return gradient;
	};

	return (
		<Box height={250}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<Bar ref={barRef} options={options} data={chartData} />
			)}
		</Box>
	);
};

export default BarChart;
