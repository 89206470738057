import React, { useContext, useEffect, useState } from "react";
import { LinearProgress, Paper, Typography } from "@mui/material";
import { InfoAlert } from "../../../components/InfoAlert";
import api from "../../../services/api";
import { Box } from "@mui/system";
import { formatCurrency } from "../../../utils/formatter";
import { DateTime } from "luxon";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import AuthContext from "../../../contexts/auth";

interface HistoryProps {
	created_at: string;
	value: string;
	label: string;
	status: string;
}

const History = () => {
	const { width } = useWindowDimensions();
	const { user } = useContext(AuthContext);
	const [history, setHistory] = useState<HistoryProps[]>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [saldo, setSaldo] = useState(0);

	useEffect(() => {
		getHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let saldoCalculo = 0;

		history.map((item) => {
			saldoCalculo += parseFloat(item.value);
			return item;
		});

		setSaldo(saldoCalculo);
	}, [history]);

	const getHistory = () => {
		setLoading(true);
		api.get("/seller/transaction/" + user?.data?.id)
			.then((res) => {
				setLoading(false);
				const filtered = res.data.data.filter(
					(i: HistoryProps) => i.status === "approved"
				);
				setHistory(filtered);
			})
			.catch((err) => {
				setLoading(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					{loading && <LinearProgress className="loader" />}
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Histórico
					</Typography>

					<Box
						width="100%"
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
							paddingTop: 1,
							marginBottom: 1,
						}}
					>
						<Typography variant="h5" fontWeight="300">
							Seu saldo
						</Typography>
						<Typography fontWeight="bold" variant="h5">
							{formatCurrency(saldo)}
						</Typography>
					</Box>

					{history.map((item, index) => (
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
								paddingTop: 1,
							}}
							key={index.toString()}
						>
							<Typography variant="body2" fontWeight="300">
								{DateTime.fromISO(item.created_at).toFormat(
									"dd/MM/yyyy HH:mm"
								)}
							</Typography>

							<Typography fontWeight="500" variant="body2">
								{formatCurrency(parseFloat(item.value))}
							</Typography>
						</Box>
					))}
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
		</>
	);
};

export default History;
