import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	Filler,
	ChartOptions,
} from "chart.js";
import { IChartData, ILineChart } from "./LineChart";
import { formatCurrency } from "../../../utils/formatter";
import { Box, CircularProgress } from "@mui/material";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend
);

const options: ChartOptions<"line"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
		},
		y: {
			ticks: {
				callback: function (value, index, ticks) {
					return formatCurrency(
						typeof value === "string" ? parseFloat(value) : value
					);
				},
			},
			suggestedMin: 0,
		},
	},
};

const LineChart: React.FC<ILineChart> = ({ data, loading }) => {
	const [chartData, setChartData] = useState<IChartData>();
	const lineRef = useRef<ChartJS<"line", number[], string>>(null);

	useEffect(() => {
		if (data) {
			buildData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const buildData = () => {
		let valueArr: number[] = [];
		let labelArr: string[] = [];

		const chart = lineRef.current;
		data.map((item) => {
			labelArr.push(item.label);
			valueArr.push(item.value);
			return true;
		});

		setChartData({
			labels: labelArr,
			datasets: [
				{
					tension: 0.5,
					fill: true,
					label: "",
					data: valueArr,
					borderColor: "#dd4291",
					borderWidth: 2,
					backgroundColor: chart
						? createGradient(chart.ctx)
						: "#dd4291",
				},
			],
		});
	};

	const createGradient = (ctx: CanvasRenderingContext2D) => {
		const colorStart = "#dd4291cc";
		const colorEnd = "#ffbbdecc";

		const gradient = ctx.createLinearGradient(100, 0, 100, 400);

		gradient.addColorStop(0, colorStart);
		gradient.addColorStop(1, colorEnd);

		return gradient;
	};

	return (
		<Box height={250}>
			{chartData && !loading ? (
				<Line ref={lineRef} options={options} data={chartData} />
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</Box>
	);
};

export default LineChart;
