/* eslint-disable no-restricted-globals */
import { useContext, useEffect, useState } from "react";
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Toolbar,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Drawer } from "../../../components/Drawer";
import { Template } from "../../../components/Template";
import { TopBar } from "../../../components/TopBar";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { InfoAlert } from "../../../components/InfoAlert";
import AuthContext from "../../../contexts/auth";

interface ProductsProps {
	id: number;
	name: string;
}

const ProdutosSellerForm = () => {
	const navigation = useNavigate();
	const { user } = useContext(AuthContext);
	const { sellerId } = useParams();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [productsSelected, setProductsSelected] = useState<string[]>([]);
	const [products, setProducts] = useState<ProductsProps[]>([]);

	useEffect(() => {
		if (user?.data.id) {
			getProducts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const getProducts = () => {
		setLoading(true);
		api.get(`/product`)
			.then((res) => {
				setProducts(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (sellerId && products.length > 0) {
			getForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sellerId, products]);

	const handleChange = (
		event: SelectChangeEvent<typeof productsSelected>
	) => {
		const {
			target: { value },
		} = event;
		setProductsSelected(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	const getForm = () => {
		setLoading(true);

		api.get("/product/seller/" + sellerId)
			.then((res) => {
				const filtered = res.data.data.map((item: any) => item.id);
				setProductsSelected(filtered);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				setError("Não foi possível buscar os dados.");
			});
	};

	const save = async () => {
		setLoading(true);
		try {
			validateFields();

			newItem();
		} catch (error) {
			setLoading(false);
		}
	};

	const newItem = () => {
		api.post("/product-seller", {
			products_id: productsSelected,
			seller_id: sellerId,
		})
			.then(() => {
				setLoading(false);
				navigation("/vendedores");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const validateFields = () => {
		if (productsSelected.length <= 0) {
			setError("Selecione pelo menos um produto.");
			throw new Error("Products is blank");
		}

		return true;
	};

	return (
		<Template>
			<TopBar title="Produtos" />
			<Drawer />
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<Box component={Paper} sx={{ padding: 3 }}>
					<Typography variant="h4" fontWeight="700" component="h1">
						Preencha todas as informações
					</Typography>
					<Grid container spacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel id="event">Produto</InputLabel>
								<Select
									multiple
									labelId="product"
									id="product"
									value={productsSelected}
									label="Produtos"
									onChange={handleChange}
								>
									{products.map((item) => (
										<MenuItem value={item.id} key={item.id}>
											{item.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>

					<Button
						variant="contained"
						sx={{ marginTop: 3, marginRight: 3 }}
						onClick={() => save()}
					>
						Salvar
					</Button>
				</Box>
			</Box>

			<InfoAlert
				onClose={() => setSuccess(false)}
				open={success}
				text="Salvo com sucesso"
				type="success"
			/>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default ProdutosSellerForm;
