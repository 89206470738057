import { Route, Routes } from "react-router-dom";
import Categorias from "../views/admin/Categorias";
import CategoriasForm from "../views/admin/Categorias/form";
import Dashboard from "../views/admin/Dashboard";
import DashboardList from "../views/admin/DashboardList";
import Produtos from "../views/admin/Produtos";
import ProdutosForm from "../views/admin/Produtos/form";
import ProdutosSeller from "../views/admin/ProdutosSeller";
import ProdutosSellerForm from "../views/admin/ProdutosSeller/form";
import Vendedores from "../views/admin/Vendedores";
import VendedoresForm from "../views/admin/Vendedores/form";
import PageNotFound from "../views/PageNotFound";
import Clientes from "../views/admin/Clientes";

const AdminRoutes = () => {
	return (
		<Routes>
			<Route path="/not-found" element={<PageNotFound />} />

			<Route path="/categorias" element={<Categorias />} />
			<Route path="/categorias/form" element={<CategoriasForm />} />
			<Route path="/categorias/edit/:id" element={<CategoriasForm />} />
			<Route path="/vendedores" element={<Vendedores />} />
			<Route path="/vendedores/edit/:id" element={<VendedoresForm />} />
			<Route path="/vendedores/form" element={<VendedoresForm />} />
			<Route
				path="/vendedores/:sellerId/edit/:id"
				element={<VendedoresForm />}
			/>

			<Route path="/clientes" element={<Clientes />} />

			<Route path="/produtos/:sellerId" element={<ProdutosSeller />} />
			<Route
				path="/produtos/:sellerId/form"
				element={<ProdutosSellerForm />}
			/>
			<Route
				path="/produtos/:sellerId/edit/:id"
				element={<ProdutosForm />}
			/>

			<Route path="/produtos" element={<Produtos />} />
			<Route path="/produtos/form" element={<ProdutosForm />} />
			<Route path="/produtos/edit/:id" element={<ProdutosForm />} />
			<Route path="/dashboard" element={<Dashboard />} />
			<Route
				path="/dashboard-list/:event_id"
				element={<DashboardList />}
			/>
		</Routes>
	);
};

export default AdminRoutes;
