import { useContext, useEffect, useState } from "react";
import {
	Button,
	Grid,
	LinearProgress,
	Paper,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Drawer } from "../../../components/Drawer";
import { Template } from "../../../components/Template";
import { TopBar } from "../../../components/TopBar";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { InfoAlert } from "../../../components/InfoAlert";
import AuthContext from "../../../contexts/auth";

const CategoriasForm = () => {
	const { id } = useParams();
	const navigation = useNavigate();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [category, setCategory] = useState("");
	const usrCtx = useContext(AuthContext);

	useEffect(() => {
		if (id) {
			getForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const getForm = () => {
		setLoading(true);

		api.get("/category/" + id)
			.then((res) => {
				setLoading(false);
				setCategory(res.data.data.name);
			})
			.catch(() => {
				setLoading(false);
				setError("Não foi possível buscar os dados.");
			});
	};

	const save = async () => {
		setLoading(true);
		try {
			validateFields();

			if (id) {
				editItem();
			} else {
				newItem();
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const newItem = () => {
		api.post("/category", {
			name: category,
			user_id: usrCtx.user?.data.id,
		})
			.then(() => {
				setLoading(false);
				navigation("/categorias");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const editItem = () => {
		api.put("/category/" + id, {
			name: category,
			user_id: usrCtx.user?.data.id,
		})
			.then(() => {
				setLoading(false);
				navigation("/categorias");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const validateFields = () => {
		if (category === "") {
			setError("Preencha todos os campos.");
			throw new Error("Category is blank");
		}

		return true;
	};

	return (
		<Template>
			<TopBar title="Categorias" />
			<Drawer />
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<Box component={Paper} sx={{ padding: 3 }}>
					<Typography variant="h4" fontWeight="700" component="h1">
						Preencha todas as informações
					</Typography>
					<Grid container spacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12} md={4}>
							<TextField
								id="categoria"
								label="Nome da Categoria"
								variant="outlined"
								value={category}
								onChange={(v) => setCategory(v.target.value)}
								fullWidth
							/>
						</Grid>
					</Grid>

					<Button
						variant="contained"
						sx={{ marginTop: 3, marginRight: 3 }}
						onClick={() => save()}
					>
						Salvar
					</Button>
				</Box>
			</Box>

			<InfoAlert
				onClose={() => setSuccess(false)}
				open={success}
				text="Deletado com sucesso"
				type="success"
			/>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default CategoriasForm;
