import React, { useEffect, useState } from "react";
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	ChartOptions,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { IChartData, IDoughnutChart } from "./DoughnutChart";
import { Box, CircularProgress } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const options: ChartOptions<"doughnut"> = {
	responsive: true,
	plugins: {
		legend: {
			position: "left" as const,
		},
		title: {
			display: false,
		},
	},
	aspectRatio: 2,
};

const DoughnutChart: React.FC<IDoughnutChart> = ({ data, loading }) => {
	const [chartData, setChartData] = useState<IChartData>();

	useEffect(() => {
		if (data) {
			buildData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const buildData = () => {
		let valueArr: number[] = [];
		let labelArr: string[] = [];

		data.map((item) => {
			labelArr.push(item.label);
			valueArr.push(item.value);
			return true;
		});

		setChartData({
			labels: labelArr,
			datasets: [
				{
					label: "",
					data: valueArr,
					backgroundColor: [
						"#DD4291",
						"#17aae3",
						"#FFFF00",
						"#802C88",
						"#2EDE2C",
					],
				},
			],
		});
	};

	return (
		<Box height={250}>
			{chartData && !loading ? (
				<Doughnut data={chartData} options={options} />
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</Box>
	);
};

export default DoughnutChart;
