import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../contexts/auth";
import AdminRoutes from "./admin.routes";
import ClientRoutes from "./client.routes";
import SellerRoutes from "./seller.routes";
import UserMasterRoutes from "./usermaster.routes";

const RoutesComponent = () => {
	let history = useNavigate();
	let location = useLocation();
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (!user) {
			if (
				location.pathname !== "/forgotpassword" &&
				location.pathname !== "/login" &&
				location.pathname !== "/admin-login" &&
				location.pathname !== "/seller-login" &&
				location.pathname !== "/qr-code" &&
				location.pathname !== "/history" &&
				location.pathname !== "/"
			) {
				history("/not-found");
			} else if (location.pathname === "/" && location.search === "") {
				window.location.href = "https://qrpag.com.br";
			}
		} else if (
			(location.pathname === "/login" ||
				location.pathname === "/" ||
				location.pathname === "/admin-login" ||
				location.pathname === "/seller-login") &&
			user.data.role === 1
		) {
			history("/dashboard");
		} else if (
			(location.pathname === "/login" ||
				location.pathname === "/" ||
				location.pathname === "/admin-login" ||
				location.pathname === "/seller-login") &&
			user.data.role === 2
		) {
			history("/dashboard");
		} else if (
			location.pathname === "/login" ||
			location.pathname === "/" ||
			location.pathname === "/admin-login" ||
			location.pathname === "/seller-login"
		) {
			history("/order");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	if (user?.data.role === 1) {
		return <UserMasterRoutes />;
	} else if (user?.data.role === 2) {
		return <AdminRoutes />;
	} else if (user) {
		return <SellerRoutes />;
	} else {
		return <ClientRoutes />;
	}
};

export default RoutesComponent;
