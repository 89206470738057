import { Close, Delete } from "@mui/icons-material";
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Typography,
} from "@mui/material";
import { ProductsSelectedProps } from ".";
import { cleanValue, formatCurrency } from "../../../utils/formatter";

interface ICart {
	onClose: () => void;
	deleteItem: (i: ProductsSelectedProps) => void;
	products: ProductsSelectedProps[];
}

const Cart: React.FC<ICart> = ({ onClose, products, deleteItem }) => {
	return (
		<Box className="fullscreen-dialog">
			<Box className="flex-row align-center space-between">
				<Typography variant="h6" component="h1" fontWeight="bold">
					Lista de pedidos
				</Typography>
				<IconButton onClick={() => onClose()}>
					<Close />
				</IconButton>
			</Box>

			<List sx={{ width: "100%" }}>
				{products.map((item, index) => (
					<ListItem key={index} disableGutters>
						<ListItemText
							primary={
								item.amount +
								"x - " +
								item.category_name +
								" - " +
								item.name
							}
							secondary={
								item.price +
								" / Total: " +
								formatCurrency(
									item.amount * cleanValue(item.price)
								)
							}
						/>
						<IconButton onClick={() => deleteItem(item)}>
							<Delete />
						</IconButton>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export { Cart };
