import {
	Button,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoAlert } from "../../../components/InfoAlert";
import OrderContext from "../../../contexts/order";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { cleanValue, formatCurrency } from "../../../utils/formatter";

const Checkout = () => {
	const { width } = useWindowDimensions();
	const app = useContext(OrderContext);
	const navigation = useNavigate();
	const [error, setError] = useState("");

	const calculateTotalPrice = () => {
		let total = 0;

		app.products.map(
			(item) => (total += item.amount * cleanValue(item.price))
		);

		return formatCurrency(total);
	};

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Checkout
					</Typography>
					<Typography
						variant="body1"
						component="h2"
						sx={{ mb: 2, width: "100%" }}
					>
						Confira o pedido solicitado e, na próxima tela, faça a
						leitura do QR Code do cliente.
					</Typography>

					<List sx={{ width: "100%" }}>
						{app.products.map((item, index) => (
							<ListItem key={index} disableGutters>
								<ListItemText
									primary={
										item.amount +
										"x - " +
										item.category_name +
										" - " +
										item.name
									}
									secondary={
										item.price +
										" / Total: " +
										formatCurrency(
											item.amount * cleanValue(item.price)
										)
									}
								/>
							</ListItem>
						))}
					</List>

					<Typography
						variant="h6"
						component="h4"
						fontWeight="bold"
						sx={{ mt: 1, width: "100%" }}
					>
						Valor total: {calculateTotalPrice()}
					</Typography>

					<Button
						onClick={() => navigation("/read-qr-code")}
						variant="contained"
						size="large"
						fullWidth
						sx={{ mt: 2 }}
					>
						Ler QR Code
					</Button>
					<Button
						onClick={() => navigation(-1)}
						variant="text"
						size="small"
						fullWidth
						sx={{ mt: 2 }}
					>
						Voltar
					</Button>
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
		</>
	);
};

export default Checkout;
