import { Paper, Typography } from "@mui/material";
import { useParams } from "react-router";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const ClientConfirmation = () => {
	const { width } = useWindowDimensions();
	const { message } = useParams();

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Pagamento finalizado
					</Typography>

					<Typography
						variant="body2"
						component="h3"
						sx={{ mt: 2, width: "100%" }}
					>
						{message}
					</Typography>
				</Paper>
			</div>
		</>
	);
};

export default ClientConfirmation;
