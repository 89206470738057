export const cleanValue = (v: string) => {
	let x = v.replace(",", ".").replace(/[^0-9.]/g, "");
	let xNumber = parseFloat(x);
	return xNumber;
};

export const formatCurrency = (value: number) => {
	return new Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	}).format(value);
};
