import { useContext, useEffect, useState } from "react";
import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	LinearProgress,
	Toolbar,
	Typography,
} from "@mui/material";
import { Drawer } from "../../../components/Drawer";
import api from "../../../services/api";
import { TopBar } from "../../../components/TopBar";
import { Template } from "../../../components/Template";
import { SimpleDialog } from "./SimpleDialog";
import BarChart from "../../../components/Charts/BarChart";
import LineChart from "../../../components/Charts/LineChart";
import { InfoAlert } from "../../../components/InfoAlert";
import DoughnutChart from "../../../components/Charts/DoughnutChart";
import AuthContext from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/icons-material";

interface EventsProps {
	id: string;
	date: string;
	name: string;
	image: string;
}

interface Data {
	label: string;
	value: number;
}

const Dashboard = () => {
	const navigation = useNavigate();
	const { user } = useContext(AuthContext);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [event, setEvent] = useState<EventsProps>();
	const [events, setEvents] = useState<EventsProps[]>([]);
	const [changeEvent, setChangeEvent] = useState(false);
	const [salesBySeller, setSalesBySeller] = useState<Data[]>([]);
	const [productsByCategory, setProductsByCategory] = useState<Data[]>([]);
	const [dailySales, setDailySales] = useState<Data[]>([]);
	const [loadingSalesBySeller, setLoadingSalesBySeller] = useState(false);
	const [loadingProductsByCategory, setLoadingProductsByCategory] =
		useState(false);
	const [loadingDailySales, setLoadingDailySales] = useState(false);

	useEffect(() => {
		if (user?.data.role === 1) {
			getEvents();
		} else if (user?.data.event_id) {
			setEvent({ id: user.data.event_id, date: "", name: "", image: "" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (event) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event]);

	const getEvents = () => {
		setLoading(true);
		api.get("/event")
			.then((res) => {
				setEvents(res.data.data);
				setEvent(res.data.data[0]);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	const getData = async () => {
		setLoading(true);
		setLoadingSalesBySeller(true);
		setLoadingProductsByCategory(true);
		setLoadingDailySales(true);

		try {
			const salesBySellerData = await api.get(
				`/reports/seller/event/${event?.id}`
			);
			setSalesBySeller(salesBySellerData.data.data);
			setLoadingSalesBySeller(false);

			const productsByCategoryData = await api.get(
				`/reports/seller/event/${event?.id}`
			);
			setProductsByCategory(productsByCategoryData.data.data);
			setLoadingProductsByCategory(false);

			const dailySalesData = await api.get(
				`/reports/seller/event/${event?.id}`
			);
			setDailySales(dailySalesData.data.data);
			setLoadingDailySales(false);

			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setError(
				error?.response?.status === 401
					? "Você não tem permissão"
					: "Algo de errado ocorreu"
			);
		}
	};

	return (
		<Template>
			<TopBar
				title="Dashboard"
				right={
					user?.data.role === 2 ? (
						<Button
							sx={{ color: "white" }}
							onClick={() =>
								navigator.clipboard.writeText(
									`${window.location.host}?store=${event?.id}`
								)
							}
						>
							<Link sx={{ color: "white", marginRight: "5px" }} />
							Copiar link do evento
						</Button>
					) : undefined
				}
			/>
			<Drawer />
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					bgcolor: "#eeeeee",
					p: 3,
					width: "100%",
				}}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}

				{user?.data.role === 1 && (
					<Card sx={{ minWidth: 275, marginBottom: 2, padding: 2 }}>
						<CardContent>
							<Typography
								sx={{ fontSize: 36 }}
								fontWeight="bold"
								gutterBottom
							>
								Dashboard
							</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline",
								}}
							>
								<Typography
									variant="h5"
									sx={{ fontSize: 16 }}
									component="div"
								>
									Você está vendo os dados do evento{" "}
									<b>{event?.name}</b>
								</Typography>
								<Button
									variant="text"
									onClick={() => setChangeEvent(true)}
									sx={{ marginLeft: 1 }}
								>
									Alterar
								</Button>
							</Box>
						</CardContent>
					</Card>
				)}

				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Card sx={{ padding: 2 }}>
							<CardContent>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "baseline",
									}}
								>
									<Typography
										sx={{ fontSize: 24, marginBottom: 4 }}
										fontWeight="bold"
									>
										Vendas por vendedor
									</Typography>
									<Button
										variant="text"
										onClick={() =>
											user?.data.role === 1
												? navigation(
														`/dashboard-list/${event?.id}`
												  )
												: navigation(
														`/dashboard-list/${user?.data.event_id}`
												  )
										}
										sx={{ marginLeft: 1 }}
									>
										Ver listagem
									</Button>
								</Box>
								<BarChart
									data={salesBySeller}
									loading={loadingSalesBySeller}
								/>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card sx={{ padding: 2 }}>
							<CardContent>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "baseline",
									}}
								>
									<Typography
										sx={{ fontSize: 24, marginBottom: 4 }}
										fontWeight="bold"
									>
										Produtos por categoria
									</Typography>
									<Button
										variant="text"
										onClick={() =>
											user?.data.role === 1
												? navigation(
														`/dashboard-list/${event?.id}`
												  )
												: navigation(
														`/dashboard-list/${user?.data.event_id}`
												  )
										}
										sx={{ marginLeft: 1 }}
									>
										Ver listagem
									</Button>
								</Box>
								<DoughnutChart
									data={productsByCategory}
									loading={loadingProductsByCategory}
								/>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card sx={{ padding: 2 }}>
							<CardContent>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "baseline",
									}}
								>
									<Typography
										sx={{ fontSize: 24, marginBottom: 4 }}
										fontWeight="bold"
									>
										Vendas diárias
									</Typography>
									<Button
										variant="text"
										onClick={() =>
											user?.data.role === 1
												? navigation(
														`/dashboard-list/${event?.id}`
												  )
												: navigation(
														`/dashboard-list/${user?.data.event_id}`
												  )
										}
										sx={{ marginLeft: 1 }}
									>
										Ver listagem
									</Button>
								</Box>
								<LineChart
									data={dailySales}
									loading={loadingDailySales}
								/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>

			<SimpleDialog
				open={changeEvent}
				onClose={(v) => {
					if (v) {
						setEvent(v);
					}
					setChangeEvent(false);
				}}
				events={events}
			/>
			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default Dashboard;
