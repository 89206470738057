import { Route, Routes } from "react-router-dom";
import AdminCreate from "../views/admin/AdminCreate";
import AdminForm from "../views/admin/AdminCreate/form";
import Dashboard from "../views/admin/Dashboard";
import DashboardList from "../views/admin/DashboardList";
import Eventos from "../views/admin/Eventos";
import EventosForm from "../views/admin/Eventos/form";
import UserMaster from "../views/admin/UserMaster";
import UserMasterForm from "../views/admin/UserMaster/form";
import PageNotFound from "../views/PageNotFound";

const UserMasterRoutes = () => {
	return (
		<Routes>
			<Route path="/not-found" element={<PageNotFound />} />

			<Route path="/eventos" element={<Eventos />} />
			<Route path="/eventos/form" element={<EventosForm />} />
			<Route path="/eventos/edit/:id" element={<EventosForm />} />
			<Route path="/user-master" element={<UserMaster />} />
			<Route path="/user-master/form" element={<UserMasterForm />} />
			<Route path="/user-master/edit/:id" element={<UserMasterForm />} />
			<Route path="/admin" element={<AdminCreate />} />
			<Route path="/admin/form" element={<AdminForm />} />
			<Route path="/admin/edit/:id" element={<AdminForm />} />
			<Route path="/dashboard" element={<Dashboard />} />
			<Route
				path="/dashboard-list/:event_id"
				element={<DashboardList />}
			/>
		</Routes>
	);
};

export default UserMasterRoutes;
