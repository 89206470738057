import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { formatCurrency } from "../../../utils/formatter";
import ClientContext from "../../../contexts/client";
import api from "../../../services/api";
import { ClientProps } from "../QRCode";

interface DonationProps {
  client: ClientProps | undefined;
  accessToken: string;
  setSuccess: (value: boolean) => void;
  setSuccessText: (value: string) => void;
  setError: (value: string) => void;
}

export function Donation({ client, accessToken, setSuccess, setSuccessText, setError }: DonationProps) {
  const [donateLoading, setDonateLoading] = useState(false);
  const [showConfirmationDonate, setShowConfirmationDonate] = useState(false);
  const { addClient } = useContext(ClientContext);

  const handleDonate = () => {
    setShowConfirmationDonate(false);
    setDonateLoading(true);
    addClient({
      accessToken,
    });
    api.post(`/client/${client?.id}/donate`)
      .then((res) => {
        setDonateLoading(false);
        setSuccess(true);
        setSuccessText("Valor doado com sucesso");

      })
      .catch((err) => {
        setDonateLoading(false);
        setError(
          err.response?.data?.message || "Algo de errado ocorreu"
        );
      });
  };
  return (
    <>
      <Box display="flex">
        <Button
          onClick={() => setShowConfirmationDonate(true)}
          variant="outlined"
          size="small"
          color="inherit"
          sx={{ mt: 2 }}
        >
          {donateLoading ? (
            <>
              <CircularProgress size={18} />
              <Typography ml={1} fontSize={12}>Doar saldo</Typography>
            </>
          ) : "Doar saldo"}
        </Button>
      </Box>

      <Dialog
        open={showConfirmationDonate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmação de doação.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Você está prestes a doar seu saldo de ${formatCurrency(
              client?.balance ?? 0
            )} para o nosso envento. Deseja continuar?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmationDonate(false)}>
            Não
          </Button>
          <Button onClick={() => handleDonate()}>Sim</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}