import { Button, Paper, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { InfoAlert } from "../../../components/InfoAlert";
import AuthContext from "../../../contexts/auth";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Logo from "../../../images/logo.svg";
import "./styles.css";

const SellerLogin = () => {
	const { width } = useWindowDimensions();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	const app = useContext(AuthContext);

	const login = () => {
		if (validateFields()) {
			app.signInSeller({ email, password });
		} else {
			setError("Preencha todos os campos");
		}
	};

	const validateFields = () => {
		if (email === "") {
			return false;
		}

		if (password === "") {
			return false;
		}

		return true;
	};

	return (
		<div className="container">
			<Paper className="card" elevation={width < 500 ? 0 : 1}>
				<img src={Logo} alt="" width={120} />
				<Typography
					variant="h4"
					component="h1"
					fontWeight="bold"
					sx={{ mt: 4, mb: 4 }}
				>
					Login Vendedor
				</Typography>
				<TextField
					id="email"
					label="Email"
					variant="outlined"
					type="email"
					onChange={(v) => setEmail(v.target.value)}
					value={email}
					fullWidth
					sx={{ mb: 2 }}
					onKeyDown={(evt) => evt.key === "Enter" && login()}
				/>
				<TextField
					id="password"
					label="Senha"
					variant="outlined"
					type="password"
					onChange={(v) => setPassword(v.target.value)}
					value={password}
					fullWidth
					sx={{ mb: 2 }}
					onKeyDown={(evt) => evt.key === "Enter" && login()}
				/>
				<Button
					onClick={() => login()}
					disabled={email === "" || password === ""}
					variant="contained"
					size="large"
					fullWidth
				>
					Entrar
				</Button>
			</Paper>
			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</div>
	);
};

export default SellerLogin;
