import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	CircularProgress,
	FormControl,
	Paper,
	Typography,
} from "@mui/material";
import VerificationInput from "react-verification-input";
import { InfoAlert } from "../../../components/InfoAlert";
import ClientContext from "../../../contexts/client";
import api from "../../../services/api";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const ConfirmPhone = () => {
	const { width } = useWindowDimensions();
	const navigation = useNavigate();
	const { client, addClient } = useContext(ClientContext);
	const [code, setCode] = useState("");
	const [loading, setLoading] = useState(false);
	const [codeResend, setCodeResend] = useState(45);
	const [error, setError] = useState("");

	useEffect(() => {
		const timer =
			codeResend > 0 &&
			setInterval(() => setCodeResend(codeResend - 1), 1000);
		if (timer) {
			return () => clearInterval(timer);
		}
	}, [codeResend]);

	const checkCode = () => {
		setLoading(true);

		api.post("/client/confirm", {
			confirmation_code: code,
			client_id: client?.id,
		})
			.then((res) => {
				setLoading(false);
				addClient({ ...client, accessToken: res.data.access_token });
				navigation("/credits");
			})
			.catch((err) => {
				setLoading(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};

	const resendCode = () => {
		setCodeResend(45);

		api.post("/client/register", {
			name: client?.name,
			phone: client?.phone,
			email: client?.email,
		})
			.then((res) => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Confirmação
					</Typography>
					<Typography
						variant="body1"
						component="h2"
						fontWeight="bold"
						sx={{ width: "100%" }}
					>
						Insira o código de confirmação
					</Typography>

					<Typography
						variant="body2"
						component="h3"
						sx={{ mb: 2, width: "100%" }}
					>
						Enviamos um código de 6 dígitos, via SMS e email.
					</Typography>
					<FormControl fullWidth sx={{ mt: 2 }}>
						<VerificationInput
							value={code}
							autoFocus
							onChange={(c) => setCode(c)}
							classNames={{
								character: "character",
								characterInactive: "character--inactive",
								characterSelected: "character--selected",
							}}
						/>
					</FormControl>

					<Button
						onClick={() => checkCode()}
						disabled={code.length < 6 || loading}
						variant="contained"
						size="large"
						fullWidth
						sx={{ mt: 2 }}
					>
						{loading ? <CircularProgress /> : "Confirmar"}
					</Button>

					{codeResend > 0 ? (
						<Typography color="gray">
							Reenviar em {codeResend} segundos
						</Typography>
					) : (
						<Button
							onClick={() => resendCode()}
							variant="text"
							fullWidth
							sx={{ mt: 2 }}
						>
							Reenviar código
						</Button>
					)}
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
		</>
	);
};

export default ConfirmPhone;
