import { SignInProps } from "../types/signIn";
import { UserProps } from "../types/user";
import api from "./api";

interface LoginResponseAdmin {
	user: UserProps;
}

export const signInAdmin = ({ email, password }: SignInProps) => {
	return new Promise<LoginResponseAdmin>((resolve, reject) => {
		api.post("/login", { email, password })
			.then((res) => {
				resolve({
					user: {
						data: {
							id: res.data.data.id,
							name: res.data.data.name,
							email: res.data.data.email,
							role: res.data.data.role,
							event_id: res.data.data.event_id,
						},
						access_token: res.data.access_token,
						token_type: res.data.token_type,
						admin: true,
					},
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const signInSeller = ({ email, password }: SignInProps) => {
	return new Promise<LoginResponseAdmin>((resolve, reject) => {
		api.post("/login-seller", { email, password })
			.then((res) => {
				resolve({
					user: {
						data: {
							name: res.data.data.name,
							email: res.data.data.email,
							id: res.data.data.id,
							role: res.data.data.role,
							event_id: res.data.data.event_id,
						},
						access_token: res.data.access_token,
						token_type: res.data.token_type,
						admin: false,
					},
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
