import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { InfoAlertProps } from "./InfoAlert";

const InfoAlert: React.FC<InfoAlertProps> = ({ open, onClose, type, text, duration = 3000 }) => {
	return (
		<Snackbar
			open={open}
			autoHideDuration={duration}
			onClose={() => onClose()}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Alert
				onClose={() => onClose()}
				severity={type}
				sx={{ width: "100%" }}
			>
				{text}
			</Alert>
		</Snackbar>
	);
};

export { InfoAlert };
