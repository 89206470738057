import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	LinearProgress,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Toolbar,
	Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { Drawer } from "../../../components/Drawer";
import api from "../../../services/api";
import { TopBar } from "../../../components/TopBar";
import { Template } from "../../../components/Template";
import { InfoAlert } from "../../../components/InfoAlert";
import { formatCurrency } from "../../../utils/formatter";
import AuthContext from "../../../contexts/auth";
import { useParams } from "react-router-dom";

interface EventsProps {
	id: number;
	date: string;
	name: string;
}

interface ItemProps {
	transaction_id: string;
	product_name: string;
	seller_name: string;
	price: number;
	amount: number;
	transaction_datetime: string;
	category_name: string;
}

const DashboardList = () => {
	const { event_id } = useParams();
	const { user } = useContext(AuthContext);
	const [error, setError] = useState("");
	const [items, setItems] = useState<ItemProps[]>([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(1);
	const [event, setEvent] = useState("");
	const [events, setEvents] = useState<EventsProps[]>([]);

	useEffect(() => {
		if (user?.data.role === 1) {
			getEvents();
		}
	}, [user]);

	useEffect(() => {
		if (!event) {
			setEvent(event_id || "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [events]);

	useEffect(() => {
		getItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const getEvents = () => {
		setLoading(true);
		api.get("/event")
			.then((res) => {
				setEvents(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	const getItems = () => {
		setLoading(true);

		api.get(
			`reports/sale/event/${event || event_id}/20?page=${currentPage}`
		)
			.then((res) => {
				setLastPage(res.data.meta.last_page);
				setCurrentPage(res.data.meta.current_page);
				setItems(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	return (
		<Template>
			<TopBar title="Histórico" />
			<Drawer />
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: "100%" }}>
						<TableHead>
							<TableRow>
								<TableCell>Nome do Produto</TableCell>
								<TableCell>Vendedor</TableCell>
								<TableCell align="center">Quantidade</TableCell>
								<TableCell align="center">
									Preço Unitário
								</TableCell>
								<TableCell align="center">
									Preço Total
								</TableCell>
								<TableCell align="center">
									Data da Venda
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((row) => (
								<TableRow
									key={row.transaction_id}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell component="th" scope="row">
										{row.product_name}
									</TableCell>
									<TableCell component="th" scope="row">
										{row.seller_name}
									</TableCell>
									<TableCell
										align="center"
										component="th"
										scope="row"
									>
										{row.amount}
									</TableCell>
									<TableCell
										align="center"
										component="th"
										scope="row"
									>
										{formatCurrency(row.price)}
									</TableCell>
									<TableCell
										align="center"
										component="th"
										scope="row"
									>
										{formatCurrency(row.price * row.amount)}
									</TableCell>
									<TableCell
										align="center"
										component="th"
										scope="row"
									>
										{DateTime.fromFormat(
											row.transaction_datetime,
											"dd/MM/yyyy HH:mm:ss"
										).toFormat("dd/MM/yyyy")}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{items.length === 0 && (
						<Box sx={{ padding: 3 }}>
							<Typography color="GrayText">
								Não foram encontrados itens
							</Typography>
						</Box>
					)}
					<Box sx={{ padding: 2, justifyContent: "flex-end" }}>
						<Pagination
							count={lastPage}
							page={currentPage}
							onChange={(e, v) => setCurrentPage(v)}
						/>
					</Box>
				</TableContainer>
			</Box>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default DashboardList;
