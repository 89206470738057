import { Box } from "@mui/system";
import React from "react";
import { TemplateProps } from "./Template";

const Template: React.FC<TemplateProps> = ({ children }) => {
	return (
		<Box
			sx={{
				display: "flex",
				width: `calc(100% - ${250}px)`,
				ml: `${250}px`,
				height: "100vh",
				flex: 1,
				minHeight: "100vh",
			}}
		>
			{children}
		</Box>
	);
};

export { Template };
