import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import Input from "../../../../components/Input";
import { cleanValue } from "../../../../utils/formatter";
import { ClientProps } from "../../../client/QRCode";

interface ClientModalProps {
  showModal: boolean;
  setCreditsModal: (value: boolean) => void;
  selected: ClientProps | undefined;
  value: string;
  setValue: (value: string) => void;
  valueDisabled: boolean;
  loading: boolean;
  save: () => void;
}

const ClientModal = ({
  showModal,
  setCreditsModal,
  selected,
  valueDisabled,
  loading,
  save,
  value,
  setValue
}: ClientModalProps) => {

  return (
    <Dialog
      open={showModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5" fontWeight="700" component="h1" textAlign="center">
          Inserir créditos
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1" fontWeight="600" component="h3" textAlign="center">
            Confirme os dados com o Cliente antes de prosseguir
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nome"
                variant="outlined"
                value={selected?.name}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail"
                variant="outlined"
                value={selected?.email}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Telefone"
                variant="outlined"
                value={selected?.phone}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                onChangeText={(v) => setValue(v)}
                placeholder="Valor do Crédito"
                value={value}
                fullWidth
                maskType="money"
                focused
                disabled={valueDisabled}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ marginBottom: 1 }}
          onClick={() => setCreditsModal(false)}
          disabled={loading}
        >
          Fechar
        </Button>
        <Button
          variant="contained"
          sx={{ marginBottom: 1, marginRight: 2 }}
          onClick={() => save()}
          disabled={valueDisabled || cleanValue(value) === 0 || value === ''}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientModal;