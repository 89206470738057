import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	CircularProgress,
	FormControl,
	Paper,
	Typography,
} from "@mui/material";
import { InfoAlert } from "../../../components/InfoAlert";
import ClientContext from "../../../contexts/client";
import api from "../../../services/api";
import Input from "../../../components/Input";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { cleanValue } from "../../../utils/formatter";
import useMercadopago from "../../../utils/mercadopago";
import { getPaymentMethod } from "../../../services/mercadoPagoForm";
import Visa from "../../../images/logos-cartoes/visa.svg";
import Amex from "../../../images/logos-cartoes/amex.svg";
import Elo from "../../../images/logos-cartoes/elo.svg";
import Master from "../../../images/logos-cartoes/master.svg";
import { validateCpf } from "../../../utils/validateCpf";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

interface InfoProps {
	status: string;
	msg: string;
}

const Creditcard = () => {
	const { width } = useWindowDimensions();
	const navigation = useNavigate();
	const { client } = useContext(ClientContext);
	const [cardHolder, setCardHolder] = useState("");
	const [expirationDate, setExpirationDate] = useState("");
	const [cardNumber, setCardNumber] = useState("");
	const [cvv, setCvv] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [paymentMethod, setPaymentMethod] = useState("");
	const [issuer, setIssuer] = useState("");
	const [cpf, setCpf] = useState("");
	const [info, setInfo] = useState<InfoProps>();

	const mp = useMercadopago("APP_USR-f0040c9b-c325-4260-94bd-cb884294e499");
	// const mp = useMercadopago("TEST-e9d2be81-8987-43e6-8e49-246ee73f7d20");

	useEffect(() => {
		const getPaymentMethodId = async () => {
			const p = await getPaymentMethod(cardNumber, mp, paymentMethod);
			setPaymentMethod(p?.paymentMethodID || "");
			setIssuer(p?.issuer?.id || "");
		};
		getPaymentMethodId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardNumber]);

	useEffect(() => {
		if (info?.status === "ok") {
			navigation(
				"/client-confirmation/O pagamento foi aprovado. Aguarde que em breve você receberá o link do seu QR Code."
			);
		} else if (info?.status === "processing") {
			navigation(
				"/client-confirmation/O pagamento está em análise. Aguarde que em breve você receberá a confirmação do pagamento."
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [info]);

	const instantiateCardFormMercadoPago = async () => {
		const token = await mp?.createCardToken({
			cardNumber: cleanValue(cardNumber).toString(),
			cardholderName: cardHolder,
			identificationType: "CPF",
			securityCode: cvv,
			cardExpirationMonth: DateTime.fromFormat(
				expirationDate,
				"MM/yy"
			).toFormat("MM"),
			cardExpirationYear: DateTime.fromFormat(
				expirationDate,
				"MM/yy"
			).toFormat("yy"),
		});

		return token.id;
	};

	const checkout = async () => {
		setLoading(true);
		try {
			validateFields();

			const token = await instantiateCardFormMercadoPago();

			const res = await api.post("/checkout/credit", {
				transaction_id: client?.transactionId,
				token,
				installments: 1,
				payment_method_id: paymentMethod,
				issuer_id: issuer,
				payer: {
					email: client?.email,
					identification: {
						type: "CPF",
						number: cpf,
					},
				},
			});

			switch (res.data.data.status_detail) {
				case "accredited":
					setInfo({ status: "ok", msg: "O pagamento foi aprovado." });
					break;

				case "cc_rejected_other_reason":
					setError(
						"O pagamento foi rejeitado, atualize os dados do cartão."
					);
					break;

				case "pending_contingency":
					setInfo({
						status: "processing",
						msg: "O pagamento está em processamento.",
					});
					break;

				case "cc_rejected_call_for_authorize":
					setError(
						"O pagamento foi rejeitado, entre em contato com a operadora do cartão."
					);
					break;

				case "cc_rejected_insufficient_amount":
					setError(
						"O pagamento foi rejeitado, confira o limite do cartão."
					);
					break;

				case "cc_rejected_bad_filled_security_code":
					setError(
						"O pagamento foi rejeitado, código de segurança incorreto."
					);
					break;

				case "cc_rejected_bad_filled_date":
					setError(
						"O pagamento foi rejeitado, data de vencimento incorreta."
					);
					break;

				case "cc_rejected_bad_filled_other":
					setError(
						"O pagamento foi rejeitado, confira os dados do cartão."
					);
					break;

				default:
					setError("Ocorreu um erro desconhecido no pagamento.");
					break;
			}

			setLoading(false);
		} catch (error: any) {
			if (Array.isArray(error)) {
				switch (error[0]?.code) {
					case "E301":
						setError("O número do cartão é inválido.");
						break;

					case "E302":
						setError("O código de segurança é inválido.");
						break;

					case "316":
						setError("O nome do titular é inválido.");
						break;

					case "325":
						setError("A data de expiração é inválida.");
						break;

					case "326":
						setError("A data de expiração é inválida.");
						break;

					default:
						setError(error[0].message || "Algo de errado ocorreu");
						break;
				}
			}
			setLoading(false);
		}
	};

	const validateFields = () => {
		if (cardHolder === "") {
			setError("Preencha todos os campos.");
			throw new Error("cardHolder is blank");
		}

		if (paymentMethod === "") {
			setError("O número do cartão é inválido.");
			throw new Error("cardNumber is invalid");
		}

		if (cpf === "") {
			setError("Preencha todos os campos.");
			throw new Error("cpf is blank");
		} else if (!validateCpf(cpf)) {
			setError("O CPF é inválido.");
			throw new Error("cpf is invalid");
		}

		if (expirationDate === "") {
			setError("Preencha todos os campos.");
			throw new Error("expirationDate is blank");
		} else if (!DateTime.fromFormat(expirationDate, "MM/yy").isValid) {
			setError("A data de validade está incorreta.");
			throw new Error("expirationDate invalid");
		} else if (
			DateTime.fromFormat(expirationDate, "MM/yy") <= DateTime.now()
		) {
			setError("A data de validade deve ser no futuro.");
			throw new Error("expirationDate invalid");
		}

		if (cardNumber === "") {
			setError("Preencha todos os campos.");
			throw new Error("cardNumber is blank");
		} else if (!(cleanValue(cardNumber).toString().length === 16 || cleanValue(cardNumber).toString().length === 15)) {
			setError("O número do cartão está incorreto.");
			throw new Error("cardNumber invalid");
		}

		if (cvv === "") {
			setError("Preencha todos os campos.");
			throw new Error("cvv is blank");
		} else if (cvv.length < 3) {
			setError("O CVV está incorreto.");
			throw new Error("cvv invalid");
		}

		return true;
	};

	const setFlagIcon = () => {
		switch (paymentMethod) {
			case "amex":
				return <img src={Amex} alt="" width={30} />;
			case "elo":
				return <img src={Elo} alt="" width={30} />;
			case "master":
				return <img src={Master} alt="" width={30} />;
			case "visa":
				return <img src={Visa} alt="" width={30} />;
			default:
				return <img src="" alt="" width={30} />;
		}
	};

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Insira os dados do cartão
					</Typography>
					<Typography
						variant="body2"
						component="h3"
						sx={{ mb: 2, width: "100%" }}
					>
						Esse é um ambiente seguro e o pagamento será realizado
						pelo Mercado Pago.
					</Typography>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setCpf(v)}
							placeholder="CPF"
							value={cpf}
							fullWidth
							maskType="cpf"
						/>
					</FormControl>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setCardHolder(v)}
							placeholder="Nome do Titular"
							value={cardHolder}
							fullWidth
						/>
					</FormControl>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setCardNumber(v)}
							placeholder="Número do Cartão"
							value={cardNumber}
							fullWidth
							maskType="card"
							iconRight={setFlagIcon()}
						/>
					</FormControl>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setExpirationDate(v)}
							placeholder="Data de validade (mm/aa)"
							value={expirationDate}
							fullWidth
							maskType="expiration_date"
						/>
					</FormControl>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setCvv(v)}
							placeholder="CVV"
							value={cvv}
							fullWidth
							maskType="cvv"
						/>
					</FormControl>

					<Button
						onClick={() => checkout()}
						disabled={
							cardHolder === "" ||
							cvv === "" ||
							cardNumber === "" ||
							expirationDate === "" ||
							loading
						}
						variant="contained"
						size="large"
						fullWidth
						sx={{ mt: 2 }}
					>
						{loading ? <CircularProgress /> : "Efetuar pagamento"}
					</Button>
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
		</>
	);
};

export default Creditcard;
