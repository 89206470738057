import React, {
	createContext,
	useState,
	ReactNode,
	FC,
	useEffect,
} from "react";
import "./auth.css";

interface ProdutosProps {
	id: string;
	name: string;
	price: string;
	category_name: string;
	category_id: string;
	seller_id: string;
	seller_name: string;
	amount: number;
}

interface ClientProps {
	id?: string;
	name?: string;
	phone?: string;
	email?: string;
}

interface ContextProps {
	products: ProdutosProps[] | [];
	addProduct: (p: ProdutosProps[]) => void;
	client: ClientProps | undefined;
	setClient: (c: ClientProps | undefined) => void;
	transaction: string;
	setTransaction: (t: string) => void;
	reset: () => void;
}

const OrderContext = createContext<ContextProps>({
	products: [],
	addProduct: (p: ProdutosProps[]) => {},
	client: undefined,
	setClient: (c: ClientProps | undefined) => {},
	transaction: "",
	setTransaction: (t: string) => {},
	reset: () => {},
});

interface Props {
	children: ReactNode;
}

const storage = sessionStorage;

export const OrderProvider: FC<Props> = ({ children }) => {
	const [products, setProducts] = useState<ProdutosProps[]>([]);
	const [client, setClient] = useState<ClientProps | undefined>();
	const [transaction, setTransaction] = useState("");

	useEffect(() => {
		async function loadStorageData() {
			const storagedProducts = storage.getItem("@order");

			if (storagedProducts) {
				const order: ProdutosProps[] = JSON.parse(storagedProducts);
				setProducts(order);
			}
		}

		loadStorageData();
	}, []);

	const addProduct = (p: ProdutosProps[]) => {
		setProducts(p);

		storage.setItem("@order", JSON.stringify(p));
	};

	const reset = () => {
		setProducts([]);
		setClient(undefined);
		setTransaction("");
	};

	return (
		<OrderContext.Provider
			value={{
				products,
				addProduct,
				client,
				setClient,
				transaction,
				setTransaction,
				reset,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
};

export default OrderContext;
