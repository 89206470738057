import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import { AuthProvider } from "./contexts/auth";
import Routes from "./routes";
import theme from "./Theme";
import "./index.css";
import { OrderProvider } from "./contexts/order";
import { ClientProvider } from "./contexts/client";

const App = () => {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<Router>
					<div className="main-container">
						<AuthProvider>
							<OrderProvider>
								<ClientProvider>
									<Routes />
								</ClientProvider>
							</OrderProvider>
						</AuthProvider>
					</div>
				</Router>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default App;
