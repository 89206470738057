import { MercadoPago } from "../types/protocols/mp";
import { cleanValue } from "../utils/formatter";

export const getPaymentMethod = async (
	cardNumber: string,
	mp: MercadoPago | undefined,
	paymentMethod: string
) => {
	const cardNumberClean = cleanValue(cardNumber).toString();
	try {
		if (cardNumberClean.length < 6) {
			return {};
		}

		if (cardNumberClean.length >= 6) {
			let bin = cardNumberClean.substring(0, 6);
			const paymentMethods = await mp?.getPaymentMethods({
				bin: bin,
			});

			const { id: paymentMethodID, issuer } = paymentMethods.results[0];

			return { paymentMethodID, issuer };
		}
		return {};
	} catch (e) {
		console.error("error getting payment methods: ", e);
		return {};
	}
};
