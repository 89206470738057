import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { Drawer } from "../../../components/Drawer";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../../services/api";
import { TopBar } from "../../../components/TopBar";
import { Template } from "../../../components/Template";
import { InfoAlert } from "../../../components/InfoAlert";
import { cleanValue, formatCurrency } from "../../../utils/formatter";
import { History, Money, MoneyOff, QrCode } from "@mui/icons-material";
import ClientModal from "./Modals/ClientModal";
import { ClientProps } from "../../client/QRCode";

import HistoryModal from "./Modals/HistoryModal";
import QrCodeConfirmationModal from "./Modals/QrCodeConfirmationModal";
import RefundConfirmationModal from "./Modals/RefundConfirmationModal";
import AuthContext from "../../../contexts/auth";

const Clientes = () => {

	const [creditsModal, setCreditsModal] = useState(false);
	const [refundModal, setRefundModal] = useState(false);
	const [clientSelected, setClientSelected] = useState<ClientProps | undefined>([] as any);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [items, setItems] = useState<ClientProps[]>([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(1);
	const [filter, setFilter] = useState("");
	const [value, setValue] = useState("");
	const [valueDisabled, setValueDisabled] = useState(false);
	const [warning, setWarning] = useState("");
	const [historyModal, setHistoryModal] = useState(false);
	const [sendQrCodeConfirmation, setSendQrCodeConfirmation] = useState(false);
	const [alertText, setAlertText] = useState("");
	const { user } = useContext(AuthContext);
	const [refundBtn, setRefundBtn] = useState(false);

	const clientId = clientSelected?.id;

	useEffect(() => {
		if (filter !== "") {
			filterItems();
		} else {
			getItems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const getItems = () => {
		setLoading(true);
		api.get(
			`/admin/clients/paginate/20?page=${currentPage}`
		)
			.then((res) => {
				setLastPage(res.data.meta.last_page);
				setCurrentPage(res.data.meta.current_page);
				setItems(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	const filterItems = () => {
		setLoading(true);
		api.get("/admin/clients/filter/?search=" + filter)
			.then((res) => {
				setLastPage(res.data?.meta?.last_page || 1);
				setCurrentPage(res.data?.meta?.current_page || 1);
				setItems(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	const save = async () => {
		setValueDisabled(true);
		setLoading(true);
		console.log(value)
		try {
			if (value === "") {
				setError("Preencha todos os campos.");
				throw new Error("Value is blank");
			}
			createTransaction();
		} catch (error) {
			setLoading(false);
		}
	};

	const createTransaction = () => {
		api.post("/admin/event/transaction", {
			client_id: clientId,
			value: cleanValue(value),
		})
			.then(() => {
				clearForm();
				setAlertText("Créditos inseridos com sucesso!");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}
				setLoading(false);
				setValueDisabled(false);
			});
	};

	const clearForm = () => {
		setLoading(false);
		setSuccess(true);
		setValue("");
		setClientSelected(undefined);
		setValueDisabled(false);
		setCreditsModal(false);
		setSendQrCodeConfirmation(false);
	};

	const sendQrCode = () => {
		setLoading(true);
		api.post("/client/resend-qr-code", {
			client_id: clientId,
		})
			.then(() => {
				clearForm();
				setAlertText("QR Code enviado com sucesso!");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}
				setLoading(false);
			});
	}

	const handleRefund = () => {
		setLoading(true);
		api.post(`admin/client/${clientId}/refund`)
			.then(() => {
				clearForm();
				setAlertText("Devolução realizada com sucesso!");
				setRefundModal(false);
			})
			.catch((err) => {
				setRefundModal(false);
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}
				setLoading(false);
			});
	}

	useEffect(() => {
		if (user?.data.id) {
			showButtons();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const showButtons = () => {
		api.get(`client/event/${user?.data.event_id}/donationAndRefund`)
			.then((res) => {
				setRefundBtn(res.data.data.refund);
			})
			.catch((err) => {
				setLoading(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};

	return (
		<Template>
			<TopBar title="Clientes" />
			<Drawer />
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<TableContainer component={Paper}>
					<Box sx={{ padding: 3, alignItems: "center" }}>
						<TextField
							id="filter"
							label="Filtrar"
							placeholder="Nome, E-mail ou Telefone"
							variant="outlined"
							value={filter}
							onChange={(v) => setFilter(v.target.value)}
							size="small"
							sx={{ width: 300 }}
						/>
						<Button
							variant="contained"
							onClick={() => filterItems()}
							sx={{ marginLeft: 1 }}
							disabled={filter === ""}
						>
							<SearchIcon />
						</Button>

						<Button
							variant="text"
							size="small"
							onClick={() => {
								setFilter("");
								getItems();
							}}
							sx={{ marginLeft: 1 }}
						>
							Limpar filtro
						</Button>
					</Box>
					<Table sx={{ minWidth: "100%" }}>
						<TableHead>
							<TableRow>
								<TableCell>Nome</TableCell>
								<TableCell>E-mail</TableCell>
								<TableCell>Telefone</TableCell>
								<TableCell>Saldo</TableCell>
								<TableCell align="center">Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((row) => (
								<TableRow
									key={row.id}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell component="th" scope="row">
										{row.name}
									</TableCell>
									<TableCell component="th" scope="row">
										{row.email}
									</TableCell>
									<TableCell component="th" scope="row">
										{row.phone}
									</TableCell>
									<TableCell component="th" scope="row">
										{formatCurrency(row.balance || 0)}
									</TableCell>
									<TableCell align="center">

										<Tooltip title="Inserir Créditos">
											<IconButton
												aria-label="credits"
												onClick={() => {
													setCreditsModal(true);
													setClientSelected(row);
												}}
											>
												<Money />
											</IconButton>
										</Tooltip>

										<Tooltip title="Histórico">
											<IconButton
												aria-label="history"
												onClick={() => {
													setHistoryModal(true)
													setClientSelected(row);
												}}
											>
												<History />
											</IconButton>
										</Tooltip>

										<Tooltip title="Reenviar QR Code">
											<IconButton
												aria-label="qrcode"
												onClick={() => {
													setSendQrCodeConfirmation(true);
													setClientSelected(row);
												}}
											>
												<QrCode />
											</IconButton>
										</Tooltip>

										{refundBtn ? (
											<Tooltip title="Devolução de saldo">
												<span>
													<IconButton
														disabled={row?.balance ? false : true}
														aria-label="refund"
														onClick={() => {
															setRefundModal(true);
															setClientSelected(row);
														}}
													>
														<MoneyOff />
													</IconButton>
												</span>
											</Tooltip>
										) : <></>}

									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{items.length === 0 && (
						<Box sx={{ padding: 3 }}>
							<Typography color="GrayText">
								Não foram encontrados itens
							</Typography>
						</Box>
					)}
					<Box sx={{ padding: 2, justifyContent: "flex-end" }}>
						<Pagination
							count={lastPage}
							page={currentPage}
							onChange={(e, v) => setCurrentPage(v)}
						/>
					</Box>
				</TableContainer>
			</Box>

			<ClientModal
				showModal={creditsModal}
				setCreditsModal={setCreditsModal}
				selected={clientSelected}
				value={value}
				setValue={setValue}
				valueDisabled={valueDisabled}
				loading={loading}
				save={save}
			/>

			<HistoryModal
				open={historyModal}
				setHistoryModal={setHistoryModal}
				client={clientSelected}
			/>

			<QrCodeConfirmationModal
				showSendQrCodeConfirmation={sendQrCodeConfirmation}
				setShowSendQrCodeConfirmation={setSendQrCodeConfirmation}
				client={clientSelected}
				sendQrCode={sendQrCode}
				loading={loading}
			/>

			<RefundConfirmationModal
				showModal={refundModal}
				setRefundModal={setRefundModal}
				selected={clientSelected}
				loading={loading}
				save={handleRefund}
			/>

			<InfoAlert
				onClose={() => setSuccess(false)}
				open={success}
				text={alertText}
				duration={5000}
				type="success"
			/>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>

			<InfoAlert
				onClose={() => setWarning("")}
				open={warning !== ""}
				text={warning}
				type="warning"
				duration={99999999}
			/>
		</Template >
	);
};

export default Clientes;
