import React, { useEffect } from "react";
import { TextField } from "@mui/material";

interface InputProps {
	onChangeText: (v: string) => void;
	value: string;
	placeholder: string;
	multline?: boolean;
	secureTextEntry?: boolean;
	autoCapitalize?: string;
	maskType?:
	| "phone"
	| "cpf"
	| "cpf_cnpj"
	| "date"
	| "zipCode"
	| "money"
	| "cvv"
	| "card"
	| "expiration_date";
	required?: boolean;
	disabled?: boolean;
	focused?: boolean;
	type?: "number" | "date";
	style?: React.CSSProperties;
	fullWidth?: boolean;
	iconRight?: React.ReactNode;
}

const Input: React.FC<InputProps> = ({
	onChangeText,
	value,
	placeholder,
	multline,
	autoCapitalize = "sentences",
	maskType,
	required,
	disabled,
	type,
	fullWidth,
	iconRight,
	focused,
}) => {
	useEffect(() => {
		if (value) {
			changeValue(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const changeValue = (v: string) => {
		var x: string | null | RegExpMatchArray = "";

		switch (maskType) {
			case "phone":
				if (v.length > 14) {
					x = v
						.replace(/\D/g, "")
						.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);

					if (x) {
						v = !x[2]
							? x[1]
							: "(" +
							x[1] +
							") " +
							x[2] +
							(x[3] ? "-" + x[3] : "");
					}
				} else {
					x = v
						.replace(/\D/g, "")
						.match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
					if (x) {
						v = !x[2]
							? x[1]
							: "(" +
							x[1] +
							") " +
							x[2] +
							(x[3] ? "-" + x[3] : "");
					}
				}
				break;
			case "cpf":
				x = v
					.replace(/\D/g, "")
					.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);

				if (x) {
					v = !x[2]
						? x[1]
						: x[1] +
						"." +
						x[2] +
						(x[3] ? "." + x[3] : "") +
						(x[4] ? "-" + x[4] : "");
				}

				break;
			case "cpf_cnpj":
				if (v.length >= 15) {
					x = v
						.replace(/\D/g, "")
						.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);

					if (x) {
						v = !x[2]
							? x[1]
							: x[1] +
							"." +
							x[2] +
							(x[3] ? "." + x[3] : "") +
							(x[4] ? "/" + x[4] : "") +
							(x[5] ? "-" + x[5] : "");
					}
				} else {
					x = v
						.replace(/\D/g, "")
						.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);

					if (x) {
						v = !x[2]
							? x[1]
							: x[1] +
							"." +
							x[2] +
							(x[3] ? "." + x[3] : "") +
							(x[4] ? "-" + x[4] : "");
					}
				}

				break;
			case "zipCode":
				x = v.replace(/\D/g, "").match(/(\d{0,5})(\d{0,3})/);

				if (x) {
					v = !x[2] ? x[1] : x[1] + "-" + x[2];
				}
				break;
			case "date":
				x = v.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

				if (x) {
					v = !x[2]
						? x[1]
						: x[1] + "/" + x[2] + (x[3] ? "/" + x[3] : "");
				}
				break;
			case "money":
				if (!isNaN(parseInt(v.replace(/[^0-9.]/g, "")))) {
					if (
						v.substring(v.length - 1) !== "," &&
						v.substring(v.length - 1) !== "."
					) {
						const decimals = v.split(",");
						const numberValue = parseFloat(
							v.replace(",", ".").replace(/[^0-9.]/g, "")
						);

						if (decimals[1]?.length > 0) {
							v = `R$ ${numberValue
								.toFixed(
									decimals[1].length > 2
										? 2
										: decimals[1].length
								)
								.toString()
								.replace(".", ",")}`;
						} else {
							v = `R$ ${numberValue
								.toString()
								.replace(".", ",")}`;
						}
					} else {
						v = v.replace(".", ",");
					}
				} else {
					v = "R$ 0";
				}

				break;
			case "cvv":
				x = v.replace(/\D/g, "").match(/(\d{0,4})/);
				if (x) {
					v = x[0];
				}
				break;
			case "expiration_date":
				x = v.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})/);

				if (x) {
					v = !x[2] ? x[1] : x[1] + "/" + x[2];
				}
				break;
			case "card":
				x = v
					.replace(/\D/g, "")
					.match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);

				if (x) {
					v = !x[2]
						? x[1]
						: x[1] +
						" " +
						x[2] +
						(x[3] ? " " + x[3] : "") +
						(x[4] ? " " + x[4] : "");
				}
				break;
			default:
				break;
		}

		onChangeText(v);
	};

	return (
		<TextField
			focused={focused}
			disabled={disabled}
			required={required}
			label={placeholder}
			variant="outlined"
			value={value}
			onChange={(text) => changeValue(text.target.value)}
			fullWidth={fullWidth}
			autoCapitalize={autoCapitalize}
			multiline={multline}
			rows={6}
			type={type || "text"}
			inputProps={{
				autoComplete: "new-password",
			}}
			InputProps={{
				endAdornment: iconRight,
			}}
		/>
	);
};

export default Input;
