import { Cameraswitch } from "@mui/icons-material";
import { Box, Fab, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { InfoAlert } from "../../../components/InfoAlert";
import Loader from "../../../components/Loader";
import OrderContext from "../../../contexts/order";
import api from "../../../services/api";
import "./styles.css";

const ReadQrCode = () => {
	const { setClient } = useContext(OrderContext);
	const [qrResult, setQrResult] = useState<string>("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [facingMode, setFacingMode] =
		useState<ConstrainDOMString>("environment");
	const navigation = useNavigate();

	useEffect(() => {
		if (qrResult !== "") {
			checkQrCode();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [qrResult]);

	const checkQrCode = () => {
		setLoading(true);
		try {
			api.post("/client/qrcode", { hash: qrResult })
				.then((res) => {
					setClient(res.data.data);
					navigation("/confirmation/" + res.data.data.id);
				})
				.catch((err) => {
					setLoading(false);
					setQrResult("");
					setError(
						err?.response?.status === 401
							? "Você não tem permissão"
							: "Algo de errado ocorreu"
					);
				});
		} catch (error) {
			console.error(error);
			setError("Algo de errado ocorreu");
		}
	};

	const handleScan = (data: any) => {
		if (data) {
			setQrResult(data);
		}
	};

	// const handleError = (err: any) => {
	// 	if (err?.message === "Permission denied") {
	// 		setError("Não é permitido utilizar a câmera");

	// 		setTimeout(() => {
	// 			navigation(-1);
	// 		}, 2000);
	// 	} else {
	// 		console.log(err);
	// 		setError("Algo de errado ocorreu");
	// 	}
	// };

	return (
		<>
			{loading ? (
				<div className="container">
					<Loader visible />
				</div>
			) : (
				<Box
					sx={{
						overflow: "hidden",
						height: "100vh",
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{facingMode === "environment" ? (
						<QrReader
							key="environmentQR"
							onResult={(result, error) => {
								if (result?.getText()) {
									handleScan(result.getText());
								}
							}}
							constraints={{ facingMode: "environment" }}
							containerStyle={{
								width: "100%",
								height: "100%",
								display: "flex",
							}}
							videoContainerStyle={{
								height: "100%",
							}}
							videoStyle={{
								width: "auto",
								height: "100%",
								minHeight: "100vh",
								left: "-50%",
							}}
							scanDelay={2000}
						/>
					) : (
						<QrReader
							key="userQR"
							onResult={(result, error) => {
								if (result?.getText()) {
									handleScan(result.getText());
								}
							}}
							constraints={{ facingMode: "user" }}
							containerStyle={{
								width: "100%",
								height: "100%",
								display: "flex",
							}}
							videoContainerStyle={{
								height: "100%",
							}}
							videoStyle={{
								width: "auto",
								height: "100%",
								minHeight: "100vh",
								left: "-50%",
							}}
							scanDelay={2000}
						/>
					)}

					{/* <QrReader
						delay={500}
						style={{
							height: "100%",
							objectFit: "fill",
						}}
						onError={handleError}
						onScan={handleScan}
						constraints={{
							facingMode: { exact: "environment" },
						}}
					/> */}
					<Box className="overlay-qr-code">
						<Typography className="text-qr-code-reader">
							Escaneie o QR Code
						</Typography>
						<Box className="overlay-internal-square" />
						<Fab
							color="secondary"
							aria-label="trocar-camera"
							className="switch-cam"
							onClick={() =>
								setFacingMode(
									facingMode === "user"
										? "environment"
										: "user"
								)
							}
						>
							<Cameraswitch />
						</Fab>
					</Box>
				</Box>
			)}
			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</>
	);
};

export default ReadQrCode;
