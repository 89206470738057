import { Add, Delete, Remove, ShoppingCart } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	Typography,
	Input as InputMUI,
	InputAdornment,
	Grid,
	IconButton,
	Badge,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoAlert } from "../../../components/InfoAlert";
import Input from "../../../components/Input";
import AuthContext from "../../../contexts/auth";
import OrderContext from "../../../contexts/order";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import api from "../../../services/api";
import { cleanValue, formatCurrency } from "../../../utils/formatter";
import { Cart } from "./Cart";
import "./styles.css";

interface ProdutosProps {
	id: string;
	name: string;
	price: string;
	category_name: string;
	category_id: string;
	seller_id: string;
	seller_name: string;
}

interface CategoriasProps {
	id: string;
	name: string;
}

export interface ProductsSelectedProps {
	id: string;
	name: string;
	price: string;
	category_name: string;
	category_id: string;
	seller_id: string;
	seller_name: string;
	amount: number;
}

const Order = () => {
	const { width } = useWindowDimensions();
	const { user } = useContext(AuthContext);
	const orderCtx = useContext(OrderContext);
	const navigation = useNavigate();
	const [price, setPrice] = useState("");
	const [product, setProduct] = useState("");
	const [products, setProducts] = useState<ProdutosProps[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<
		ProductsSelectedProps[]
	>([]);
	const [categories, setCategories] = useState<CategoriasProps[]>([]);
	const [category, setCategory] = useState("");
	const [error, setError] = useState("");
	const [amount, setAmount] = useState("1");
	const [loading, setLoading] = useState(false);
	const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
	const [showCart, setShowCart] = useState(false);

	useEffect(() => {
		getCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setSelectedProducts(orderCtx.products);
	}, [orderCtx]);

	useEffect(() => {
		if (category !== "") {
			getProducts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category]);

	useEffect(() => {
		if (product !== "") {
			setPrice(getPriceFromProduct(product));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product]);

	const getCategories = () => {
		setLoading(true);
		api.get("/seller/category/" + user?.data.id)
			.then((res) => {
				setCategories(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				if (err?.response?.data?.error === "ProductEmptyException") {
					setCategories([]);
				} else {
					setError(
						err?.response?.status === 401
							? "Você não tem permissão"
							: "Algo de errado ocorreu"
					);
				}

				setLoading(false);
			});
	};

	const getProducts = () => {
		setLoading(true);
		api.get("/seller/product/" + user?.data.id + "/" + category)
			.then((res) => {
				setProducts(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				if (err?.response?.data?.error === "ProductEmptyException") {
					setProducts([]);
				} else {
					setError(
						err?.response?.status === 401
							? "Você não tem permissão"
							: "Algo de errado ocorreu"
					);
				}

				setLoading(false);
			});
	};

	const getPriceFromProduct = (productId: string) => {
		return products.find((i) => i.id === productId)?.price || "";
	};

	const save = async () => {
		orderCtx.addProduct(selectedProducts);
		navigation("/checkout");
	};

	const saveProduct = () => {
		let newArray: ProductsSelectedProps[] = selectedProducts;

		const productProp = getProduct(product);

		if (productProp) {
			newArray.push({
				id: product,
				price,
				name: productProp.name,
				category_name: productProp.category_name,
				category_id: productProp.category_id,
				seller_id: productProp.seller_id,
				seller_name: productProp.seller_name,
				amount: parseInt(amount),
			});
		}

		setSelectedProducts([...newArray]);

		setPrice("");
		setAmount("1");
		setProduct("");
		setProducts([]);
		setCategory("");
	};

	const getProduct = (productId: string) => {
		return products.find((i) => i.id === productId);
	};

	const countItems = () => {
		let counter = 0;

		selectedProducts.map((item) => (counter += item.amount));

		return counter;
	};

	const calculateTotalPrice = () => {
		let total = 0;

		selectedProducts.map(
			(item) => (total += item.amount * cleanValue(item.price))
		);

		return formatCurrency(total);
	};

	const removeItem = (i: ProductsSelectedProps) => {
		setSelectedProducts(selectedProducts.filter((item) => item !== i));
	};

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					{loading && <LinearProgress className="loader" />}
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Olá, {user?.data.name}
					</Typography>
					<Typography
						variant="body1"
						component="h2"
						sx={{ mb: 2, width: "100%" }}
					>
						Preencha os campos abaixo e realize a cobrança na
						próxima tela
					</Typography>
					<FormControl fullWidth sx={{ m: 2 }}>
						<InputLabel id="categoryLabel">Categorias</InputLabel>
						<Select
							labelId="categoryLabel"
							id="category"
							value={category}
							label="Categoria"
							onChange={(v) => setCategory(v.target.value)}
						>
							{categories.map((item) => (
								<MenuItem value={item.id} key={item.id}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl fullWidth sx={{ m: 2 }}>
						<InputLabel id="productLabel">Produtos</InputLabel>
						<Select
							labelId="productLabel"
							id="product"
							value={product}
							label="Produtos"
							onChange={(v) => setProduct(v.target.value)}
						>
							{products.map((item) => (
								<MenuItem value={item.id} key={item.id}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Grid container spacing={2} sx={{ my: 1 }}>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<Input
									onChangeText={(v) => setPrice(v)}
									placeholder="Preço"
									value={price}
									fullWidth
									maskType="money"
									disabled
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<InputLabel htmlFor="standard-adornment-amount">
									Quantidade
								</InputLabel>
								<InputMUI
									id="standard-adornment-amount"
									readOnly
									inputProps={{
										style: { textAlign: "center" },
									}}
									value={amount}
									startAdornment={
										<InputAdornment position="start">
											<IconButton
												aria-label="Remover"
												disabled={parseInt(amount) <= 1}
												onClick={() =>
													setAmount(
														(
															parseInt(amount) - 1
														).toString()
													)
												}
											>
												<Remove />
											</IconButton>
										</InputAdornment>
									}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="Adicionar"
												onClick={() =>
													setAmount(
														(
															parseInt(amount) + 1
														).toString()
													)
												}
											>
												<Add />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Button
						onClick={() => saveProduct()}
						disabled={price === "" || product === ""}
						variant="contained"
						size="large"
						fullWidth
						sx={{ mt: 2 }}
					>
						Adicionar
					</Button>
					<Button
						onClick={() => navigation("/history")}
						variant="text"
						size="small"
						fullWidth
						sx={{ mt: 2, mb: 4 }}
					>
						Visualizar histórico de cobrança
					</Button>
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
			{selectedProducts.length > 0 && (
				<Box className="footer-cart">
					<Button
						variant="text"
						onClick={() => setShowConfirmationDelete(true)}
					>
						<Delete />
					</Button>
					<Button variant="text" onClick={() => save()}>
						Avançar
					</Button>
					<Box
						className="cart-container"
						onClick={() => setShowCart(true)}
					>
						<Badge badgeContent={countItems()} color="primary">
							<ShoppingCart color="primary" />
						</Badge>
						<Typography
							variant="caption"
							color="primary.main"
							fontWeight="bold"
							fontSize={10}
						>
							{calculateTotalPrice()}
						</Typography>
					</Box>
				</Box>
			)}

			<Dialog
				open={showConfirmationDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Deseja limpar todo o carrinho?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Todos os produtos da listagem serão removidos
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowConfirmationDelete(false)}>
						Não
					</Button>
					<Button
						onClick={() => {
							setSelectedProducts([]);
							setShowConfirmationDelete(false);
						}}
					>
						Sim
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showCart}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullScreen
			>
				<Cart
					onClose={() => setShowCart(false)}
					products={selectedProducts}
					deleteItem={(i) => removeItem(i)}
				/>
			</Dialog>
		</>
	);
};

export default Order;
