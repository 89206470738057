import { Route, Routes } from "react-router-dom";
import AdminLogin from "../views/admin/AdminLogin";
import ClientConfirmation from "../views/client/ClientConfirmation";
import ConfirmPhone from "../views/client/ConfirmPhone";
import Creditcard from "../views/client/CreditCard";
import Credits from "../views/client/Credits";
import Pix from "../views/client/Pix";
import QRCode from "../views/client/QRCode";
import Register from "../views/client/Register";
import PageNotFound from "../views/PageNotFound";
import SellerLogin from "../views/seller/SellerLogin";

const ClientRoutes = () => {
	return (
		<Routes>
			<Route path="/not-found" element={<PageNotFound />} />

			<Route path="/seller-login" element={<SellerLogin />} />
			<Route path="/admin-login" element={<AdminLogin />} />

			<Route path="/" element={<Register />} />
			<Route path="/confirm-phone" element={<ConfirmPhone />} />
			<Route path="/credits" element={<Credits />} />
			<Route
				path="/client-confirmation/:message"
				element={<ClientConfirmation />}
			/>
			<Route path="/credit-card" element={<Creditcard />} />
			<Route path="/pix" element={<Pix />} />
			<Route path="/qr-code" element={<QRCode />} />
		</Routes>
	);
};

export default ClientRoutes;
