import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./styles.css";

interface LoaderProps {
	visible: boolean;
}

const Loader: React.FC<LoaderProps> = ({ visible }) => {
	return visible ? (
		<Box className="container">
			<Box className="modal">
				<CircularProgress />
			</Box>
		</Box>
	) : null;
};

export default Loader;
