import React, {
	createContext,
	useState,
	useEffect,
	ReactNode,
	FC,
} from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import api from "../services/api";
import * as Auth from "../services/auth";
import { SignInProps } from "../types/signIn";
import { UserProps } from "../types/user";
import "./auth.css";

interface ContextProps {
	user: UserProps | null;
	signInAdmin: ({ email, password }: SignInProps) => void;
	signInSeller: ({ email, password }: SignInProps) => void;
	signOut: () => void;
}

const storage = sessionStorage;

const AuthContext = createContext<ContextProps>({
	user: null,
	signInAdmin: () => {},
	signOut: () => {},
	signInSeller: () => {},
});

interface Props {
	children: ReactNode;
}

export const AuthProvider: FC<Props> = ({ children }) => {
	const [user, setUser] = useState<UserProps | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	let history = useNavigate();

	useEffect(() => {
		async function loadStorageData() {
			setLoading(true);
			const storagedUser = storage.getItem("@Auth:user");

			if (storagedUser) {
				const userJson: UserProps = JSON.parse(storagedUser);
				api.defaults.headers.common = {
					Authorization: `${userJson.token_type} ${userJson.access_token}`,
				};
				setUser(userJson);
			}

			setLoading(false);
		}

		loadStorageData();
	}, []);

	const signInAdmin = ({ email, password }: SignInProps) => {
		setLoading(true);
		Auth.signInAdmin({ email, password })
			.then((response) => {
				api.defaults.headers.common = {
					Authorization: `${response.user.token_type} ${response.user.access_token}`,
				};

				setLoading(false);
				setUser(response.user);
				storage.setItem("@Auth:user", JSON.stringify(response.user));
				storage.setItem("@Auth:admin", "true");
			})
			.catch((err) => {
				setLoading(false);
				if (err?.response?.data?.message) {
					Swal.fire("Oops...", err?.response?.data?.message, "error");
				} else {
					Swal.fire(
						"Oops...",
						"Erro desconhecido, contacte o administrador.",
						"error"
					);
				}
			});
	};

	const signInSeller = ({ email, password }: SignInProps) => {
		setLoading(true);
		Auth.signInSeller({ email, password })
			.then((response) => {
				api.defaults.headers.common = {
					Authorization: `${response.user.token_type} ${response.user.access_token}`,
				};
				setLoading(false);
				setUser(response.user);
				storage.setItem("@Auth:user", JSON.stringify(response.user));
				storage.setItem("@Auth:admin", "true");
			})
			.catch((err) => {
				setLoading(false);
				if (err?.response?.data?.message) {
					Swal.fire("Oops...", err?.response?.data?.message, "error");
				} else {
					Swal.fire(
						"Oops...",
						"Erro desconhecido, contacte o administrador.",
						"error"
					);
				}
			});
	};

	const signOut = async () => {
		history("/admin-login");
		storage.clear();
		setUser(null);
	};

	if (loading) {
		return (
			<div className="container">
				<Loader visible />
			</div>
		);
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				signInAdmin,
				signOut,
				signInSeller,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
