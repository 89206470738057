import {
	Button,
	LinearProgress,
	ListItem,
	Paper,
	Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { InfoAlert } from "../../../components/InfoAlert";
import AuthContext from "../../../contexts/auth";
import OrderContext from "../../../contexts/order";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import api from "../../../services/api";
import { cleanValue, formatCurrency } from "../../../utils/formatter";

const Confirmation = () => {
	const { width } = useWindowDimensions();
	const { client, products, setClient, reset } = useContext(OrderContext);
	const { user } = useContext(AuthContext);
	const navigation = useNavigate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const saveShopping = async () => {
		setLoading(true);

		try {
			const productsArr = products.map((item) => {
				return {
					product_id: item.id,
					product_price: cleanValue(item.price),
					product_quantity: item.amount,
				};
			});

			const resTransaction = await api.post("/seller/transaction", {
				seller_id: user?.data.id,
				products: productsArr,
			});

			await api.put(
				"/seller/transaction/" + resTransaction.data.data.id,
				{
					client_id: client?.id,
					status: "approved",
				}
			);

			setLoading(false);
			reset();

			Swal.fire({
				title: "Sucesso",
				text: "Cobrança efetuada com sucesso!",
				icon: "success",
				timer: 4000,
				timerProgressBar: true,
				showConfirmButton: false,
			}).then(() => {
				navigation("/order");
			});
		} catch (error: any) {
			setLoading(false);

			if (error?.response?.status === 400) {
				Swal.fire({
					title: "Ops...",
					text:
						error?.response?.data?.message ||
						"Algo de errado ocorreu",
					icon: "error",
					timer: 4000,
					timerProgressBar: true,
					showConfirmButton: false,
				}).then(() => {
					navigation("/order");
				});
			} else {
				setError(
					error?.response?.status === 401
						? "Você não tem permissão"
						: error?.response?.data?.message ||
								"Algo de errado ocorreu"
				);
			}
		}

		// api.post("/seller/transaction", {
		// 	seller_id: user?.data.id,
		// 	products: productsArr,
		// 	client_id: client?.id,
		// 	status: "approved",
		// })
		// 	.then((res) => {
		// 		setLoading(false);

		// 		reset();

		// 		Swal.fire({
		// 			title: "Sucesso",
		// 			text: "Cobrança efetuada com sucesso!",
		// 			icon: "success",
		// 			timer: 4000,
		// 			timerProgressBar: true,
		// 			showConfirmButton: false,
		// 		}).then(() => {
		// 			navigation("/order");
		// 		});
		// 	})
		// 	.catch((err) => {
		// 		setLoading(false);
		// 		setError(
		// 			err?.response?.status === 401
		// 				? "Você não tem permissão"
		// 				: "Algo de errado ocorreu"
		// 		);
		// 	});

		// api.put("/seller/transaction/" + transaction, {
		// 	client_id: client?.id,
		// 	status: "approved",
		// })
		// 	.then(() => {
		// 		setLoading(false);
		// 		reset();

		// 		Swal.fire({
		// 			title: "Sucesso",
		// 			text: "Cobrança efetuada com sucesso!",
		// 			icon: "success",
		// 			timer: 4000,
		// 			timerProgressBar: true,
		// 			showConfirmButton: false,
		// 		}).then(() => {
		// 			navigation("/order");
		// 		});
		// 	})
		// 	.catch((err) => {
		// 		setLoading(false);
		// 		setError(
		// 			err?.response?.status === 401
		// 				? "Você não tem permissão"
		// 				: "Algo de errado ocorreu"
		// 		);
		// 	});
	};

	const calculateProducts = () => {
		let totalValue = 0;
		if (products.length > 0) {
			products.map(
				(item) => (totalValue += cleanValue(item.price) * item.amount)
			);

			return formatCurrency(totalValue);
		}

		return "R$ 0";
	};

	const DataList = () => {
		return (
			<>
				<ListItem
					disablePadding
					style={{
						justifyContent: "space-between",
						paddingBottom: 10,
					}}
				>
					<Typography
						fontWeight="bold"
						variant="body1"
						component="h2"
						sx={{ marginRight: 2 }}
					>
						Valor
					</Typography>
					<Typography variant="body1" component="h2">
						{calculateProducts()}
					</Typography>
				</ListItem>
				<ListItem
					disablePadding
					style={{
						justifyContent: "space-between",
						paddingBottom: 10,
					}}
				>
					<Typography
						fontWeight="bold"
						variant="body1"
						component="h2"
						sx={{ marginRight: 2 }}
					>
						Cliente
					</Typography>
					<Typography variant="body1" component="h2">
						{client?.name}
					</Typography>
				</ListItem>
				<ListItem
					disablePadding
					style={{
						justifyContent: "space-between",
						paddingBottom: 10,
					}}
				>
					<Typography
						fontWeight="bold"
						variant="body1"
						component="h2"
						sx={{ marginRight: 2 }}
					>
						Celular
					</Typography>
					<Typography variant="body1" component="h2">
						(**) *****-**{client?.phone?.slice(-2)}
					</Typography>
				</ListItem>
			</>
		);
	};

	const goBack = () => {
		setClient(undefined);
		navigation(-1);
	};

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					{loading && <LinearProgress className="loader" />}
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Confirmação
					</Typography>
					<Typography
						variant="body1"
						component="h2"
						sx={{ mb: 2, width: "100%" }}
					>
						Confira as informações abaixo antes de confirmar a
						cobrança
					</Typography>

					<Typography
						variant="body1"
						component="h2"
						sx={{ mb: 2, width: "100%" }}
					>
						Dados do cliente
					</Typography>

					<DataList />

					<Button
						onClick={() => saveShopping()}
						variant="contained"
						size="large"
						fullWidth
						sx={{ mt: 2 }}
					>
						Confirmar cobrança
					</Button>
					<Button
						onClick={() => goBack()}
						variant="text"
						size="small"
						fullWidth
						sx={{ mt: 2 }}
					>
						Escanear novamente
					</Button>
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
		</>
	);
};

export default Confirmation;
