import { useEffect, useState } from "react";
import {
	Button,
	FormControlLabel,
	Grid,
	IconButton,
	LinearProgress,
	Paper,
	Switch,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import { Box } from "@mui/system";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { DateTime } from "luxon";
import { Drawer } from "../../../components/Drawer";
import { Template } from "../../../components/Template";
import { TopBar } from "../../../components/TopBar";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/Input";
import api from "../../../services/api";
import { InfoAlert } from "../../../components/InfoAlert";
import { Delete } from "@mui/icons-material";
import axios from "axios";

const EventosForm = () => {
	const navigation = useNavigate();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [name, setName] = useState("");
	const [date, setDate] = useState("");
	const [refund, setRefund] = useState(false);
	const [donation, setDonation] = useState(false);
	const [images, setImages] = useState<ImageListType | []>([]);

	useEffect(() => {
		if (id) {
			getForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const onChange = (imageList: ImageListType) => {
		setImages(imageList);
	};

	const getForm = () => {
		setLoading(true);

		api.get("/event/" + id)
			.then((res) => {
				setLoading(false);
				setName(res.data.data.name);
				setDate(
					DateTime.fromFormat(
						res.data.data.date,
						"yyyy-MM-dd"
					).toFormat("dd/MM/yyyy")
				);
				setRefund(res.data.data.refund);
				setDonation(res.data.data.donation);

				saveImageArr(res.data.data.image);
			})
			.catch(() => {
				setLoading(false);
				setError("Não foi possível buscar os dados.");
			});
	};

	const saveImageArr = async (image: any) => {
		const imageArr = [];
		imageArr.push({
			data_url: await getBase64(image),
		});

		setImages(imageArr);
	};

	async function getBase64(url: any) {
		const extension = url.split(".")[url.split(".").length - 1];
		const response = await axios.get(url, {
			responseType: "arraybuffer",
		});
		return `data:image/${extension};base64,${Buffer.from(
			response.data,
			"binary"
		).toString("base64")}`;
	}

	const save = async () => {
		setLoading(true);
		try {
			validateFields();

			if (id) {
				editItem();
			} else {
				newItem();
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const newItem = () => {
		api.post("/event", {
			name,
			date: DateTime.fromFormat(date, "dd/MM/yyyy").toFormat(
				"yyyy-MM-dd"
			),
			image: images.length === 0 ? "" : images[0].data_url,
			refund,
			donation,
		})
			.then(() => {
				setLoading(false);
				navigation("/eventos");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const editItem = () => {
		api.put("/event/" + id, {
			name,
			date: DateTime.fromFormat(date, "dd/MM/yyyy").toFormat(
				"yyyy-MM-dd"
			),
			image: images.length === 0 ? "" : images[0].data_url,
			refund,
			donation,
		})
			.then(() => {
				setLoading(false);
				navigation("/eventos");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const validateFields = () => {
		if (name === "") {
			setError("Preencha todos os campos.");
			throw new Error("Name is blank");
		}

		if (date === "") {
			setError("Preencha todos os campos.");
			throw new Error("Date is blank");
		} else if (!DateTime.fromFormat(date, "dd/MM/yyyy").isValid) {
			setError("A data está incorreta.");
			throw new Error("Wrong format of date");
		}

		return true;
	};

	return (
		<Template>
			<TopBar title="Eventos" />
			<Drawer />
			<Box
				component="main"
				className="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<Box component={Paper} sx={{ padding: 3 }}>
					<Typography variant="h4" fontWeight="700" component="h1">
						Preencha todas as informações
					</Typography>
					<Grid container spacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12} md={6}>
							<TextField
								id="evento"
								label="Nome do Evento"
								variant="outlined"
								value={name}
								onChange={(v) => setName(v.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Input
								value={date}
								onChangeText={(v) => setDate(v)}
								placeholder="Data do Evento"
								maskType="date"
								fullWidth
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12} md={3}>
							<FormControlLabel
								checked={donation}
								onChange={() => setDonation(!donation)}
								control={<Switch />}
								label="Ativar Doação?"
								labelPlacement="start"
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<FormControlLabel
								checked={refund}
								onChange={() => setRefund(!refund)}
								control={<Switch />}
								label="Ativar Reembolso?"
								labelPlacement="start"
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<ImageUploading
								multiple
								value={images}
								onChange={onChange}
								maxNumber={1}
								dataURLKey="data_url"
							>
								{({
									imageList,
									onImageUpload,
									onImageRemoveAll,
									onImageUpdate,
									onImageRemove,
									isDragging,
									dragProps,
								}) => (
									<div className="upload__image-wrapper">
										{imageList.length === 0 && (
											<Button
												variant={
													isDragging
														? "outlined"
														: "contained"
												}
												onClick={onImageUpload}
												{...dragProps}
											>
												Inserir imagem
											</Button>
										)}

										<Box
											sx={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "center",
												flexDirection: "row",
											}}
										>
											{imageList.map((image, index) => (
												<div
													key={index}
													className="image-item"
													style={{
														marginRight: 10,
														marginLeft: 5,
													}}
												>
													<img
														src={image["data_url"]}
														alt=""
														height="100"
													/>
													<div className="image-item__btn-wrapper">
														<IconButton
															aria-label="delete"
															onClick={() =>
																onImageRemove(
																	index
																)
															}
														>
															<Delete />
														</IconButton>
													</div>
												</div>
											))}
										</Box>
									</div>
								)}
							</ImageUploading>
						</Grid>

					</Grid>

					<Button
						variant="contained"
						sx={{ marginTop: 3, marginRight: 3 }}
						onClick={() => save()}
					>
						Salvar
					</Button>
				</Box>
			</Box>

			<InfoAlert
				onClose={() => setSuccess(false)}
				open={success}
				text="Deletado com sucesso"
				type="success"
			/>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default EventosForm;
