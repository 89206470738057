import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Toolbar,
	Typography,
} from "@mui/material";
import { Drawer } from "../../../components/Drawer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router";
import api from "../../../services/api";
import { TopBar } from "../../../components/TopBar";
import { Template } from "../../../components/Template";
import { InfoAlert } from "../../../components/InfoAlert";
import { formatCurrency } from "../../../utils/formatter";

interface ProdutosProps {
	id: number;
	name: string;
	price: string;
	category_name: string;
}

const ProdutosSeller = () => {
	const navigation = useNavigate();
	const { sellerId } = useParams();

	const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
	const [selected, setSelected] = useState<string | number | undefined>();
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [items, setItems] = useState<ProdutosProps[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getItems = () => {
		setLoading(true);
		api.get("/product/seller/" + sellerId)
			.then((res) => {
				setItems(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				if (err?.response?.data?.error === "ProductEmptyException") {
					setItems([]);
				} else {
					setError(
						err?.response?.status === 401
							? "Você não tem permissão"
							: "Algo de errado ocorreu"
					);
				}

				setLoading(false);
			});
	};

	const deleteItem = () => {
		setShowConfirmationDelete(false);
		if (selected) {
			setLoading(true);
			api.delete(`/product/${selected}`)
				.then(() => {
					setSelected(undefined);
					getItems();
				})
				.catch((err) => {
					setSelected(undefined);
					setError(
						err?.response?.status === 401
							? "Você não tem permissão"
							: "Algo de errado ocorreu"
					);
					setLoading(false);
				});
		}
	};

	return (
		<Template>
			<TopBar title="Produtos" />
			<Drawer />
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<TableContainer component={Paper}>
					<Box sx={{ padding: 3, alignItems: "center" }}>
						<Button
							variant="contained"
							onClick={() =>
								navigation(`/produtos/${sellerId}/form/`)
							}
							sx={{ marginLeft: 3, float: "right" }}
						>
							Novo Produto
						</Button>
					</Box>
					<Table sx={{ minWidth: "100%" }}>
						<TableHead>
							<TableRow>
								<TableCell>Nome</TableCell>
								<TableCell align="center">Preço</TableCell>
								<TableCell align="center">Categoria</TableCell>
								<TableCell align="center">Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((row) => (
								<TableRow
									key={row.id}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell component="th" scope="row">
										{row.name}
									</TableCell>
									<TableCell align="center">
										{formatCurrency(parseFloat(row.price))}
									</TableCell>
									<TableCell align="center">
										{row.category_name}
									</TableCell>
									<TableCell align="center">
										<IconButton
											aria-label="edit"
											onClick={() =>
												navigation(
													`/produtos/edit/${row.id}`
												)
											}
										>
											<EditIcon />
										</IconButton>
										<IconButton
											aria-label="delete"
											onClick={() => {
												setShowConfirmationDelete(true);
												setSelected(row.id);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{items.length === 0 && (
						<Box sx={{ padding: 3 }}>
							<Typography color="GrayText">
								Não foram encontrados itens
							</Typography>
						</Box>
					)}
				</TableContainer>
			</Box>
			<Dialog
				open={showConfirmationDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Deseja apagar esse produto?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						As vendas realizadas com esse produto receberão o nome
						de "desconhecido".
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowConfirmationDelete(false)}>
						Não
					</Button>
					<Button onClick={() => deleteItem()}>Sim</Button>
				</DialogActions>
			</Dialog>

			<InfoAlert
				onClose={() => setSuccess(false)}
				open={success}
				text="Deletado com sucesso"
				type="success"
			/>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default ProdutosSeller;
