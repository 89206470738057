import {
	Avatar,
	Dialog,
	DialogTitle,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
} from "@mui/material";

interface EventsProps {
	id: string;
	date: string;
	name: string;
	image: string;
}

interface SimpleDialogProps {
	open: boolean;
	onClose: (v: EventsProps | null) => void;
	events: EventsProps[];
}

export const SimpleDialog = (props: SimpleDialogProps) => {
	const { onClose, open, events } = props;

	const handleListItemClick = (value: EventsProps) => {
		onClose(value);
	};

	return (
		<Dialog onClose={() => onClose(null)} open={open}>
			<DialogTitle>Selecione um evento</DialogTitle>
			<List sx={{ pt: 0 }}>
				{events.map((item, index) => (
					<ListItemButton
						onClick={() => handleListItemClick(item)}
						key={index}
					>
						<ListItemAvatar>
							<Avatar src={item.image} />
						</ListItemAvatar>
						<ListItemText primary={item.name} />
					</ListItemButton>
				))}
			</List>
		</Dialog>
	);
};
