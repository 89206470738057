/* eslint-disable no-restricted-globals */
import { useContext, useEffect, useState } from "react";
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Drawer } from "../../../components/Drawer";
import { Template } from "../../../components/Template";
import { TopBar } from "../../../components/TopBar";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { InfoAlert } from "../../../components/InfoAlert";
import Input from "../../../components/Input";
import { cleanValue } from "../../../utils/formatter";
import AuthContext from "../../../contexts/auth";

interface CategoriesProps {
	id: number;
	name: string;
}

const ProdutosForm = () => {
	const navigation = useNavigate();
	const { user } = useContext(AuthContext);
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [name, setName] = useState("");
	const [price, setPrice] = useState("");
	const [category, setCategory] = useState("");
	const [categories, setCategories] = useState<CategoriesProps[]>([]);

	useEffect(() => {
		if (user?.data.id) {
			getCategories();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const getCategories = () => {
		setLoading(true);
		api.get(`/category/user/${user?.data.id}`)
			.then((res) => {
				setCategories(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (id) {
			getForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const getForm = () => {
		setLoading(true);

		api.get("/product/" + id)
			.then((res) => {
				setLoading(false);
				setName(res.data.data.name);
				setPrice(res.data.data.price);
				setCategory(res.data.data.category_id);
			})
			.catch(() => {
				setLoading(false);
				setError("Não foi possível buscar os dados.");
			});
	};

	const save = async () => {
		setLoading(true);
		try {
			validateFields();

			if (id) {
				editItem();
			} else {
				newItem();
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const newItem = () => {
		api.post("/product", {
			name,
			price: cleanValue(price),
			category_id: category,
			event_id: user?.data.event_id,
		})
			.then(() => {
				setLoading(false);
				navigation("/produtos/");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const editItem = () => {
		api.put("/product/" + id, {
			name,
			price: cleanValue(price),
			category_id: category,
			event_id: user?.data.event_id,
		})
			.then(() => {
				setLoading(false);
				navigation("/produtos/");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const validateFields = () => {
		if (name === "") {
			setError("Preencha todos os campos.");
			throw new Error("Name is blank");
		}

		if (price === "") {
			setError("Preencha todos os campos.");
			throw new Error("Price is blank");
		}
		if (category === "") {
			setError("Preencha todos os campos.");
			throw new Error("Category is blank");
		}

		return true;
	};

	return (
		<Template>
			<TopBar title="Produtos" />
			<Drawer />
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<Box component={Paper} sx={{ padding: 3 }}>
					<Typography variant="h4" fontWeight="700" component="h1">
						Preencha todas as informações
					</Typography>
					<Grid container spacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12} md={4}>
							<TextField
								id="name"
								label="Nome do produto"
								variant="outlined"
								value={name}
								onChange={(v) => setName(v.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<Input
								onChangeText={(v) => setPrice(v)}
								placeholder="Preço"
								value={price}
								fullWidth
								maskType="money"
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<FormControl fullWidth>
								<InputLabel id="event">Categoria</InputLabel>
								<Select
									labelId="category"
									id="category"
									value={category}
									label="Categoria"
									onChange={(v) =>
										setCategory(v.target.value)
									}
								>
									{categories.map((item) => (
										<MenuItem value={item.id} key={item.id}>
											{item.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>

					<Button
						variant="contained"
						sx={{ marginTop: 3, marginRight: 3 }}
						onClick={() => save()}
					>
						Salvar
					</Button>
				</Box>
			</Box>

			<InfoAlert
				onClose={() => setSuccess(false)}
				open={success}
				text="Salvo com sucesso"
				type="success"
			/>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default ProdutosForm;
