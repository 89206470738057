import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	Paper,
	Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InfoAlert } from "../../../components/InfoAlert";
import Input from "../../../components/Input";
import ClientContext from "../../../contexts/client";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import api from "../../../services/api";
import { cleanValue } from "../../../utils/formatter";
import ReCAPTCHA from "react-google-recaptcha";

interface EventProps {
	id: string;
	name: string;
	date: string;
	image: string;
}

const Register = () => {
	const { width } = useWindowDimensions();
	const { addClient } = useContext(ClientContext);
	const [searchParams] = useSearchParams();
	const navigation = useNavigate();
	const [phone, setPhone] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [loadingMain, setLoadingMain] = useState(false);
	const [eventId, setEventId] = useState("");
	const [event, setEvent] = useState<EventProps>();
	const [recaptcha, setRecaptcha] = useState("");
	const siteKey = "6LcyPE0nAAAAAMuA9FracMKI9vW_faWo5Pfe2z3r";

	useEffect(() => {
		setEventId(searchParams?.get("store") || "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	useEffect(() => {
		if (eventId !== "") {
			getEventData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventId]);

	const handleRecaptchaChange = (token: string | null) => {
		setRecaptcha(token || "");
	};

	const save = async () => {
		setLoading(true);
		try {
			validateFields();

			api.post("/client/register", {
				event_id: eventId,
				name,
				phone: cleanValue(phone),
				email,
				recaptcha,
			})
				.then((res) => {
					addClient(res.data.data);
					setLoading(false);
					navigation("/confirm-phone");
				})
				.catch((err) => {
					if (err?.response?.status === 401) {
						setError("Você não tem permissão");
					} else if (err?.response?.status === 422) {
						setError(
							err.response?.data?.message ||
							"Algo de errado ocorreu"
						);
					} else {
						setError("Algo de errado ocorreu");
					}

					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const validateFields = () => {
		if (name === "") {
			setError("Preencha todos os campos.");
			throw new Error("Name is blank");
		}

		if (phone === "") {
			setError("Preencha todos os campos.");
			throw new Error("Phone is blank");
		}

		if (email === "") {
			setError("Preencha todos os campos.");
			throw new Error("Email is blank");
		}

		return true;
	};

	const getEventData = () => {
		setLoadingMain(true);
		api.get("/event/show/" + eventId)
			.then((res) => {
				setLoadingMain(false);
				setEvent(res.data.data);
			})
			.catch((err) => {
				setLoadingMain(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};

	if (loadingMain) {
		return (
			<Box className="loadingMain">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<>
			{event?.image && (
				<Box className="img-container">
					<img src={event?.image} alt="" width="100%" />
				</Box>
			)}

			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Seja bem-vindo ao {event?.name}
					</Typography>
					<Typography
						variant="body1"
						component="h2"
						sx={{ mb: 2, width: "100%" }}
					>
						Adquira créditos e use-os no nosso evento
					</Typography>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setName(v)}
							placeholder="Nome"
							value={name}
							fullWidth
						/>
					</FormControl>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setPhone(v)}
							placeholder="DDD + Celular"
							value={phone}
							fullWidth
							maskType="phone"
						/>
					</FormControl>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setEmail(v)}
							placeholder="Email"
							value={email}
							fullWidth
						/>
					</FormControl>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<ReCAPTCHA
							sitekey={siteKey}
							onChange={handleRecaptchaChange}
						/>
					</FormControl>

					<Typography sx={{ mt: 2 }}>
						Ao cadastrar você declara que aceita os nossos{" "}
						<a
							href="https://google.com"
							target="_blank"
							rel="noreferrer"
						>
							Termos de Uso
						</a>
					</Typography>

					<Button
						onClick={() => save()}
						disabled={name === "" || phone === "" || recaptcha === "" || loading}
						variant="contained"
						size="large"
						fullWidth
						sx={{ mt: 2 }}
					>
						{loading ? <CircularProgress /> : "Cadastrar"}
					</Button>
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
		</>
	);
};

export default Register;
