import React, { useContext, useState } from "react";
import {
	Button,
	CircularProgress,
	FormControl,
	Paper,
	Typography,
} from "@mui/material";
import { InfoAlert } from "../../../components/InfoAlert";
import ClientContext from "../../../contexts/client";
import api from "../../../services/api";
import Input from "../../../components/Input";
import { cleanValue } from "../../../utils/formatter";
import { useNavigate } from "react-router-dom";
import ModalPaymentType from "./ModalPaymentType";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Credits = () => {
	const { width } = useWindowDimensions();
	const navigation = useNavigate();
	const { client, addClient } = useContext(ClientContext);
	const [amount, setAmount] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [showModalPaymentType, setShowModalPaymentType] = useState(false);

	const saveCredits = () => {
		setLoading(true);

		if (cleanValue(amount) < 5) {
			setError("Coloque um valor maior que R$ 5,00");
			return false;
		}

		api.post("/client/transaction", {
			value: cleanValue(amount),
			client_id: client?.id,
		})
			.then((res) => {
				setLoading(false);

				const { id, value } = res.data.data;
				addClient({ ...client, amount: value, transactionId: id });
				setShowModalPaymentType(true);
			})
			.catch((err) => {
				setLoading(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};

	const checkout = (p: string) => {
		setShowModalPaymentType(false);

		if (p === "pix") {
			navigation("/pix");
		} else {
			navigation("/credit-card");
		}
	};

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Créditos
					</Typography>
					<Typography
						variant="body1"
						component="h2"
						sx={{ width: "100%" }}
						fontWeight="bold"
					>
						Escolha a quantidade de créditos que você deseja
						adquirir (Mín: R$ 5,00).
					</Typography>
					<Typography
						variant="body2"
						component="h3"
						sx={{ mb: 2, width: "100%" }}
					>
						No próximo passo você irá para a tela de pagamento
					</Typography>

					<FormControl fullWidth sx={{ mt: 2 }}>
						<Input
							onChangeText={(v) => setAmount(v)}
							placeholder="Valor de crédito (R$)"
							value={amount}
							fullWidth
							maskType="money"
						/>
					</FormControl>

					<Button
						onClick={() => saveCredits()}
						disabled={amount === "" || loading}
						variant="contained"
						size="large"
						fullWidth
						sx={{ mt: 2 }}
					>
						{loading ? <CircularProgress /> : "Ir para o pagamento"}
					</Button>
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
				{showModalPaymentType && (
					<ModalPaymentType onClick={(p) => checkout(p)} />
				)}
			</div>
		</>
	);
};

export default Credits;
