import React, { createContext, useState, ReactNode, FC } from "react";
import api from "../services/api";
import "./auth.css";

interface ClientProps {
	id?: string;
	name?: string;
	phone?: string;
	accessToken?: string;
	amount?: number;
	transactionId?: string;
	email?: string;
}

interface ContextProps {
	client: ClientProps | null;
	addClient: (p: ClientProps) => void;
}

const ClientContext = createContext<ContextProps>({
	client: null,
	addClient: (p: ClientProps) => {},
});

interface Props {
	children: ReactNode;
}

export const ClientProvider: FC<Props> = ({ children }) => {
	const [client, setClient] = useState<ClientProps | null>(null);

	const addClient = (p: ClientProps) => {
		setClient(p);

		if (p.accessToken) {
			api.defaults.headers.common = {
				Authorization: `Bearer ${p.accessToken}`,
			};
		}
	};

	return (
		<ClientContext.Provider
			value={{
				client,
				addClient,
			}}
		>
			{children}
		</ClientContext.Provider>
	);
};

export default ClientContext;
