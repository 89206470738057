import { useContext, useEffect, useState } from "react";
import {
	Button,
	Grid,
	LinearProgress,
	Paper,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Drawer } from "../../../components/Drawer";
import { Template } from "../../../components/Template";
import { TopBar } from "../../../components/TopBar";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { InfoAlert } from "../../../components/InfoAlert";
import { Check, Close } from "@mui/icons-material";
import AuthContext from "../../../contexts/auth";

const VendedoresForm = () => {
	const navigation = useNavigate();
	const { id } = useParams();
	const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [changePassword, setChangePassword] = useState(false);
	const [passwordValidation, setPasswordValidation] = useState({
		has8Letters: false,
		hasLowerCaseLetter: false,
		hasCapitalLetter: false,
		hasNumber: false,
	});

	useEffect(() => {
		if (password.length >= 8) {
			setPasswordValidation((prevState) => {
				return { ...prevState, has8Letters: true };
			});
		} else {
			setPasswordValidation((prevState) => {
				return { ...prevState, has8Letters: false };
			});
		}

		const rgxNumber = /\d/;
		const rgxLowerCase = /[a-z]/;
		const rgxCapitalLetter = /[A-Z]/;

		if (rgxLowerCase.test(password)) {
			setPasswordValidation((prevState) => {
				return { ...prevState, hasLowerCaseLetter: true };
			});
		} else {
			setPasswordValidation((prevState) => {
				return { ...prevState, hasLowerCaseLetter: false };
			});
		}

		if (rgxCapitalLetter.test(password)) {
			setPasswordValidation((prevState) => {
				return { ...prevState, hasCapitalLetter: true };
			});
		} else {
			setPasswordValidation((prevState) => {
				return { ...prevState, hasCapitalLetter: false };
			});
		}

		if (rgxNumber.test(password)) {
			setPasswordValidation((prevState) => {
				return { ...prevState, hasNumber: true };
			});
		} else {
			setPasswordValidation((prevState) => {
				return { ...prevState, hasNumber: false };
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [password]);

	useEffect(() => {
		if (id) {
			getForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const getForm = () => {
		setLoading(true);

		api.get("/seller/" + id)
			.then((res) => {
				setLoading(false);
				setName(res.data.data.name);
				setEmail(res.data.data.email);
			})
			.catch(() => {
				setLoading(false);
				setError("Não foi possível buscar os dados.");
			});
	};

	const save = async () => {
		setLoading(true);
		try {
			validateFields();

			if (id) {
				editItem();
			} else {
				newItem();
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const newItem = () => {
		api.post("/seller", {
			name,
			email,
			password,
			event_id: user?.data.event_id,
		})
			.then(() => {
				setLoading(false);
				navigation("/vendedores");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const editItem = () => {
		api.put("/seller/" + id, {
			name,
			email,
			password: password === "" ? undefined : password,
			event_id: user?.data.event_id,
		})
			.then(() => {
				setLoading(false);
				navigation("/vendedores");
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					setError("Você não tem permissão");
				} else if (err?.response?.status === 422) {
					setError(
						err.response?.data?.message || "Algo de errado ocorreu"
					);
				} else {
					setError("Algo de errado ocorreu");
				}

				setLoading(false);
			});
	};

	const validateFields = () => {
		if (name === "") {
			setError("Preencha todos os campos.");
			throw new Error("Name is blank");
		}

		if (email === "") {
			setError("Preencha todos os campos.");
			throw new Error("Email is blank");
		}

		if ((changePassword && id) || !id) {
			if (password === "") {
				setError("Preencha todos os campos.");
				throw new Error("Password is blank");
			}

			if (passwordConfirmation === "") {
				setError("Preencha todos os campos.");
				throw new Error("Password Confirmation is blank");
			}

			if (password !== passwordConfirmation) {
				setError("As senhas não são idênticas");
				throw new Error("Different passwords");
			}

			if (
				!(
					passwordValidation.has8Letters ||
					passwordValidation.hasCapitalLetter ||
					passwordValidation.hasLowerCaseLetter ||
					passwordValidation.hasNumber
				)
			) {
				setError("A senha não atende aos requisitos mínimos.");
				throw new Error("Password is weak");
			}
		}

		return true;
	};

	return (
		<Template>
			<TopBar title="Vendedores" />
			<Drawer />
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<Box component={Paper} sx={{ padding: 3 }}>
					<Typography variant="h4" fontWeight="700" component="h1">
						Preencha todas as informações
					</Typography>
					<Grid container spacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12} md={4}>
							<TextField
								id="name"
								label="Nome do Comércio/Vendedor"
								variant="outlined"
								value={name}
								onChange={(v) => setName(v.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField
								id="email"
								label="Email"
								variant="outlined"
								value={email}
								onChange={(v) => setEmail(v.target.value)}
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} sx={{ marginTop: 1 }}>
						{id && !changePassword ? (
							<Grid item xs={2}>
								<Button
									variant="text"
									onClick={() => setChangePassword(true)}
								>
									Alterar senha
								</Button>
							</Grid>
						) : (
							<>
								<Grid item xs={12} md={4}>
									<TextField
										id="password"
										label="Senha"
										variant="outlined"
										value={password}
										onChange={(v) =>
											setPassword(v.target.value)
										}
										fullWidth
										type="password"
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<TextField
										id="password-confirmation"
										label="Confirmação de Senha"
										variant="outlined"
										value={passwordConfirmation}
										onChange={(v) =>
											setPasswordConfirmation(
												v.target.value
											)
										}
										fullWidth
										type="password"
									/>
								</Grid>
							</>
						)}
					</Grid>
					{id && !changePassword ? (
						<></>
					) : (
						<Grid
							container
							spacing={2}
							sx={{ marginTop: 1, padding: 2 }}
							flexDirection="column"
						>
							<Box
								flexDirection="row"
								display="flex"
								alignItems="center"
							>
								<Typography
									fontWeight="600"
									sx={{ marginLeft: 1, marginBottom: 1 }}
								>
									Requisitos de senha
								</Typography>
							</Box>
							<Box
								flexDirection="row"
								display="flex"
								alignItems="center"
							>
								{passwordValidation.has8Letters ? (
									<Check fontSize="small" color="success" />
								) : (
									<Close fontSize="small" color="error" />
								)}
								<Typography
									sx={{ marginLeft: 1 }}
									color={
										passwordValidation.has8Letters
											? "success"
											: "error"
									}
								>
									8 caracteres
								</Typography>
							</Box>

							<Box
								flexDirection="row"
								display="flex"
								alignItems="center"
							>
								{passwordValidation.hasLowerCaseLetter ? (
									<Check fontSize="small" color="success" />
								) : (
									<Close fontSize="small" color="error" />
								)}
								<Typography
									sx={{ marginLeft: 1 }}
									color={
										passwordValidation.hasLowerCaseLetter
											? "success"
											: "error"
									}
								>
									Letra minúscula
								</Typography>
							</Box>

							<Box
								flexDirection="row"
								display="flex"
								alignItems="center"
							>
								{passwordValidation.hasCapitalLetter ? (
									<Check fontSize="small" color="success" />
								) : (
									<Close fontSize="small" color="error" />
								)}
								<Typography
									sx={{ marginLeft: 1 }}
									color={
										passwordValidation.hasCapitalLetter
											? "success"
											: "error"
									}
								>
									Letra maiúscula
								</Typography>
							</Box>
							<Box
								flexDirection="row"
								display="flex"
								alignItems="center"
							>
								{passwordValidation.hasNumber ? (
									<Check fontSize="small" color="success" />
								) : (
									<Close fontSize="small" color="error" />
								)}
								<Typography
									sx={{ marginLeft: 1 }}
									color={
										passwordValidation.hasNumber
											? "success"
											: "error"
									}
								>
									Número
								</Typography>
							</Box>
						</Grid>
					)}
					<Button
						variant="contained"
						sx={{ marginTop: 3, marginRight: 3 }}
						onClick={() => save()}
					>
						Salvar
					</Button>
				</Box>
			</Box>

			<InfoAlert
				onClose={() => setSuccess(false)}
				open={success}
				text="Salvo com sucesso"
				type="success"
			/>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default VendedoresForm;
