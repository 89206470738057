import { useContext, useEffect, useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { initializeApp } from "firebase/app";
import {
	collection,
	getFirestore,
	onSnapshot,
	orderBy,
	query,
	where,
} from "firebase/firestore";
import { InfoAlert } from "../../../components/InfoAlert";
import ClientContext from "../../../contexts/client";
import api from "../../../services/api";
import Loader from "../../../components/Loader";
import { Box } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import QRCodeImg from "react-qr-code";
import { formatCurrency } from "../../../utils/formatter";
import { DateTime } from "luxon";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Donation } from "../Donation";
import { Refund } from "../Refund";

const firebaseApp = initializeApp({
	projectId: "sites-ecos",
});

export interface HistoryProps {
	created_at: { seconds: number };
	value: string;
	label: string;
	status: string;
}

export interface ClientProps {
	email: string;
	id: string;
	phone: string;
	name: string;
	balance: number;
	event_id: string;
}

const QRCode = () => {
	const db = getFirestore(firebaseApp);
	const userCollectionRef = collection(db, "qr_pag_transactions");

	const { width } = useWindowDimensions();
	const navigation = useNavigate();
	const { addClient } = useContext(ClientContext);
	const [searchParams] = useSearchParams();
	const [hash, setHash] = useState("");
	const [history, setHistory] = useState<HistoryProps[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [client, setClient] = useState<ClientProps>();
	const [accessToken, setAccessToken] = useState("");
	const [balance, setBalance] = useState(0);
	const [success, setSuccess] = useState(false);
	const [successText, setSuccessText] = useState("");
	const [donationBtn, setDonationBtn] = useState(false);
	const [refundBtn, setRefundBtn] = useState(false);

	useEffect(() => {
		if (client?.id) {
			const q = query(
				userCollectionRef,
				where("client_id", "==", client.id),
				orderBy("created_at", "desc")
			);
			onSnapshot(q, (snapshot) => {
				const t = snapshot.docs.map((doc) => ({
					...doc.data(),
				}));
				const filteredHistory = t.filter(
					(i) => i.status === "approved"
				);
				setHistory(filteredHistory as HistoryProps[]);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client]);

	useEffect(() => {
		if (hash !== "") {
			getBalance();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	useEffect(() => {
		setHash(searchParams?.get("hash") || "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	useEffect(() => {
		if (hash !== "") {
			getClientData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash]);

	useEffect(() => {
		if (balance > 0) {
			showButtons();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [balance]);

	const getClientData = () => {
		api.post("/client/show", {
			hash,
		})
			.then((res) => {
				setClient(res.data.data);
				setAccessToken(res.data.access_token);
			})
			.catch((err) => {
				setLoading(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};

	const getBalance = () => {
		setLoading(true);
		api.post("/client/show", {
			hash,
		})
			.then((res) => {
				setBalance(res.data.data.balance);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};

	const goToCredits = () => {
		addClient({
			id: client?.id,
			name: client?.name,
			email: client?.email,
			phone: client?.phone,
			accessToken,
		});
		navigation("/credits");
	};

	const getColor = (label: string) => {
		if (label === "sale") {
			return "red";
		} else if (label === "donation" || label === "refund") {
			return "orange";
		} else {
			return "green";
		}
	};

	const showButtons = () => {
		addClient({
			accessToken,
		});
		api.get(`client/event/${client?.event_id}/donationAndRefund`)
			.then((res) => {
				setDonationBtn(res.data.data.donation);
				setRefundBtn(res.data.data.refund);
			})
			.catch((err) => {
				setLoading(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};


	if (loading) {
		return (
			<div className="container">
				<Loader visible />
			</div>
		);
	}

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Olá {client?.name}
					</Typography>
					<Typography
						variant="body1"
						component="h2"
						sx={{ width: "100%" }}
						fontWeight="bold"
					>
						Apresente o QR Code abaixo para realizar pagamentos
					</Typography>
					{hash !== "" && (
						<QRCodeImg
							size={150}
							value={hash}
							viewBox={`0 0 150 150`}
							style={{ marginBottom: 24, marginTop: 24 }}
						/>
					)}
					<Typography
						variant="body2"
						component="h3"
						sx={{ mb: 2, width: "100%" }}
					>
						Caso precise de mais créditos clique no botão abaixo e
						adquira mais.
					</Typography>

					<Box
						width="100%"
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
							paddingTop: 1,
							marginBottom: 1,
						}}
					>
						<Typography variant="h5" fontWeight="300">
							Seu saldo
						</Typography>
						<Typography fontWeight="bold" variant="h5">
							{formatCurrency(balance || 0)}
						</Typography>
					</Box>

					<Button
						onClick={() => goToCredits()}
						variant="contained"
						size="large"
						fullWidth
						sx={{ mt: 2 }}
					>
						Adquirir mais créditos
					</Button>

					<Box display="flex" justifyContent="space-between" width="100%">

						{refundBtn ? (
							<Refund
								client={client}
								accessToken={accessToken}
								setSuccess={setSuccess}
								setSuccessText={setSuccessText}
								setError={setError}
							/>
						) : <></>}

						{donationBtn ? (
							<Donation
								client={client}
								accessToken={accessToken}
								setSuccess={setSuccess}
								setSuccessText={setSuccessText}
								setError={setError}
							/>
						) : <></>}

					</Box>


					<Typography
						fontWeight="bold"
						variant="h5"
						sx={{
							marginBottom: 2,
							marginTop: 3,
							alignSelf: "flex-start",
						}}
					>
						Histórico
					</Typography>

					{history.map((item, index) => (

						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
								paddingTop: 1,
							}}
							key={index.toString()}
						>
							<Typography variant="body2" fontWeight="300">
								{DateTime.fromSeconds(
									item.created_at.seconds
								).toFormat("dd/MM/yyyy HH:mm")}
								{item.label === "donation" && " (Doação)"}
								{item.label === "refund" && " (Devolução)"}
							</Typography>

							<Typography
								fontWeight="bold"
								variant="body2"
								color={() => getColor(item.label)}
							>
								{item.label === "credit" && "+"}
								{item.label === "sale" && "-"}
								{item.label === "donation" && "-"}
								{item.label === "refund" && "-"}
								{formatCurrency(parseFloat(item.value))}
							</Typography>
						</Box>
					))}
				</Paper>

				<InfoAlert
					onClose={() => setSuccess(false)}
					open={success}
					text={successText}
					type="success"
					duration={10000}
				/>

				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
		</>
	);
};

export default QRCode;
