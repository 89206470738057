import React, { useContext, useEffect, useState } from "react";
import { IconButton, Paper, Typography } from "@mui/material";
import { InfoAlert } from "../../../components/InfoAlert";
import ClientContext from "../../../contexts/client";
import api from "../../../services/api";
import Loader from "../../../components/Loader";
import { Box } from "@mui/system";
import { ContentCopy } from "@mui/icons-material";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Pix = () => {
	const { width } = useWindowDimensions();
	const { client } = useContext(ClientContext);
	const [pixImg, setPixImg] = useState("");
	const [pixKey, setPixKey] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	useEffect(() => {
		if (client) {
			getPix();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client]);

	const getPix = () => {
		setLoading(true);
		api.post("/checkout/pix", {
			transaction_id: client?.transactionId,
			email: client?.email || "guilhermemiranda.ti@gmail.com",
		})
			.then((res) => {
				setLoading(false);
				setPixImg(res.data.data.pix_qr_code_src);
				setPixKey(res.data.data.pix_key);
			})
			.catch((err) => {
				setLoading(false);
				setError(
					err.response?.data?.message || "Algo de errado ocorreu"
				);
			});
	};

	if (loading) {
		return (
			<div className="container">
				<Loader visible />
			</div>
		);
	}

	return (
		<>
			<div className="container">
				<Paper className="card" elevation={width < 500 ? 0 : 1}>
					<Typography
						variant="h4"
						component="h1"
						fontWeight="bold"
						sx={{ mt: 4, width: "100%" }}
					>
						Pagamento PIX
					</Typography>
					<Typography
						variant="body1"
						component="h2"
						sx={{ width: "100%" }}
						fontWeight="bold"
					>
						Escaneie o QR Code ou copie o código para efetuar o
						pagamento
					</Typography>
					<Typography
						variant="body2"
						component="h3"
						sx={{ mb: 2, width: "100%" }}
					>
						Realize o pagamento no valor exato dos créditos
						adquiridos.
					</Typography>

					{pixImg && <img src={pixImg} alt="QR-CODE" width={200} />}

					<Typography
						variant="body2"
						component="h3"
						sx={{ mt: 2, width: "100%" }}
					>
						Assim que o pagamento for validado você receberá um SMS
						com os seus créditos.
					</Typography>

					<Box width="100%" sx={{ mt: 2 }}>
						<Box>
							<Typography fontWeight="bold" variant="body1">
								Chave{" "}
								<IconButton
									aria-label="copy"
									onClick={() =>
										navigator.clipboard.writeText(pixKey)
									}
									color="primary"
								>
									<ContentCopy fontSize="small" />
								</IconButton>
							</Typography>
						</Box>
						<Typography
							sx={{ wordBreak: "break-all" }}
							variant="body2"
						>
							{pixKey}
						</Typography>
					</Box>
				</Paper>
				<InfoAlert
					onClose={() => setError("")}
					open={error !== ""}
					text={error}
					type="error"
				/>
			</div>
		</>
	);
};

export default Pix;
