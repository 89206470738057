import { Route, Routes } from "react-router-dom";
import PageNotFound from "../views/PageNotFound";
import Checkout from "../views/seller/Checkout";
import Confirmation from "../views/seller/Confirmation";
import History from "../views/seller/History";
import Order from "../views/seller/Order";
import ReadQrCode from "../views/seller/ReadQrCode";

const SellerRoutes = () => {
	return (
		<Routes>
			<Route path="/not-found" element={<PageNotFound />} />

			<Route path="/order" element={<Order />} />
			<Route path="/read-qr-code" element={<ReadQrCode />} />
			<Route path="/checkout" element={<Checkout />} />
			<Route path="/confirmation/:sellerId" element={<Confirmation />} />
			<Route path="/history" element={<History />} />
		</Routes>
	);
};

export default SellerRoutes;
