import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import { ClientProps } from "../../../client/QRCode";

interface QrCodeConfirmationModalProps {
  showSendQrCodeConfirmation: boolean;
  setShowSendQrCodeConfirmation: (show: boolean) => void;
  client: ClientProps | undefined;
  sendQrCode: () => void;
  loading: boolean;
}

const QrCodeConfirmationModal = ({
  showSendQrCodeConfirmation, 
  setShowSendQrCodeConfirmation, 
  client,
  sendQrCode,
  loading 
}: QrCodeConfirmationModalProps) => {
  
  return (
    <Dialog
				open={showSendQrCodeConfirmation}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
          <Typography variant="h5" fontWeight="700" component="h1" textAlign="center">
            Reenviar QR Code
          </Typography>
        </DialogTitle>
        <Divider />
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
          <Typography variant="body1" fontWeight="600" component="h3" textAlign="center">
            Confirme os dados com o Cliente antes de prosseguir
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <TextField
                label="E-mail"
                variant="outlined"
                value={client?.email}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Telefone"
                variant="outlined"
                value={client?.phone}
                fullWidth
                disabled
              />
            </Grid>
            
          </Grid>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
        <Button
          variant="outlined"
          sx={{ marginBottom: 1 }}
          onClick={() => setShowSendQrCodeConfirmation(false)}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          sx={{ marginBottom: 1, marginRight: 2 }}
          onClick={() => sendQrCode()}
          disabled={loading}
        >
          Enviar
        </Button>
				</DialogActions>
			</Dialog>
  )
}

export default QrCodeConfirmationModal