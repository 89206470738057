import { useContext } from "react";
import {
	Divider,
	Drawer as Sidebar,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import {
	AdminPanelSettings,
	Festival,
	Group,
	Leaderboard,
	Logout,
	Person,
	QrCode,
	Settings,
	ShoppingBag,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/auth";
import Logo from "../../images/logo.svg";
import { Box } from "@mui/system";

const Drawer = () => {
	const navigation = useNavigate();
	const location = useLocation();
	const app = useContext(AuthContext);
	return (
		<Sidebar
			sx={{
				flexShrink: 0,
				"& .MuiDrawer-paper": {
					width: 250,
					boxSizing: "border-box",
				},
			}}
			variant="permanent"
			anchor="left"
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					padding: 3,
				}}
			>
				<img src={Logo} alt="" width={120} />
			</Box>

			<List>
				{app.user?.data.role === 1 && (
					<>
						<ListItem disablePadding>
							<ListItemButton
								selected={location.pathname === "/dashboard"}
								onClick={() => navigation("/dashboard")}
							>
								<ListItemIcon>
									<Leaderboard />
								</ListItemIcon>
								<ListItemText primary="Relatórios" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton
								selected={
									location.pathname === "/eventos" ||
									location.pathname === "/eventos/form"
								}
								onClick={() => navigation("/eventos")}
							>
								<ListItemIcon>
									<Festival />
								</ListItemIcon>
								<ListItemText primary="Evento" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton
								selected={
									location.pathname === "/admin" ||
									location.pathname === "/admin/form"
								}
								onClick={() => navigation("/admin")}
							>
								<ListItemIcon>
									<Person />
								</ListItemIcon>
								<ListItemText primary="Administrador de evento" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton
								selected={
									location.pathname === "/user-master" ||
									location.pathname === "/user-master/form"
								}
								onClick={() => navigation("/user-master")}
							>
								<ListItemIcon>
									<AdminPanelSettings />
								</ListItemIcon>
								<ListItemText primary="Super usuário" />
							</ListItemButton>
						</ListItem>
					</>
				)}

				{app.user?.data.role === 2 && (
					<>
						<ListItem disablePadding>
							<ListItemButton
								selected={location.pathname === "/dashboard"}
								onClick={() => navigation("/dashboard")}
							>
								<ListItemIcon>
									<Leaderboard />
								</ListItemIcon>
								<ListItemText primary="Relatórios" />
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton
								selected={
									location.pathname === "/categorias" ||
									location.pathname === "/categorias/form"
								}
								onClick={() => navigation("/categorias")}
							>
								<ListItemIcon>
									<Settings />
								</ListItemIcon>
								<ListItemText primary="Categoria" />
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton
								selected={
									location.pathname === "/produtos" ||
									location.pathname === "/produtos/form"
								}
								onClick={() => navigation("/produtos")}
							>
								<ListItemIcon>
									<ShoppingBag />
								</ListItemIcon>
								<ListItemText primary="Produtos" />
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton
								selected={
									location.pathname === "/vendedores" ||
									location.pathname === "/vendedores/form"
								}
								onClick={() => navigation("/vendedores")}
							>
								<ListItemIcon>
									<Person />
								</ListItemIcon>
								<ListItemText primary="Vendedor" />
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton
								selected={location.pathname === "/clientes"}
								onClick={() => navigation("/clientes")}
							>
								<ListItemIcon>
									<Group />
								</ListItemIcon>
								<ListItemText primary="Clientes" />
							</ListItemButton>
						</ListItem>
					</>
				)}
			</List>
			<Divider />
			<List>
				<ListItem disablePadding>
					<ListItemButton onClick={() => app.signOut()}>
						<ListItemIcon>
							<Logout />
						</ListItemIcon>
						<ListItemText primary="Sair" />
					</ListItemButton>
				</ListItem>
			</List>
		</Sidebar>
	);
};

export { Drawer };
