import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { formatCurrency } from "../../../../utils/formatter"
import { ClientProps, HistoryProps } from "../../../client/QRCode";
import { useEffect, useState } from "react";
import {
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseApp = initializeApp({
  projectId: "sites-ecos",
});

interface HistoryModalProps {
  open: boolean;
  setHistoryModal: (value: boolean) => void;
  client: ClientProps | undefined;
}

const getColor = (label: string) => {
  if (label === "sale") {
    return "red";
  } else if (label === "donation" || label === "refund") {
    return "orange";
  } else {
    return "green";
  }
};

const HistoryModal = ({
  open,
  setHistoryModal,
  client,

}: HistoryModalProps) => {
  const clientId = client?.id;
  const db = getFirestore(firebaseApp);
  const userCollectionRef = collection(db, "qr_pag_transactions");
  const [history, setHistory] = useState<HistoryProps[]>([]);

  useEffect(() => {
    if (clientId) {
      const q = query(
        userCollectionRef,
        where("client_id", "==", clientId),
        orderBy("created_at", "desc")
      );
      onSnapshot(q, (snapshot) => {
        const t = snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        const filteredHistory = t.filter(
          (i) => i.status === "approved"
        );
        setHistory(filteredHistory as HistoryProps[]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5" fontWeight="700" component="h1" textAlign="center">
          Histórico de Créditos e Débitos
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <Typography variant="h5" fontWeight="300">
              Saldo atual
            </Typography>
            <Typography fontWeight="bold" variant="h5">
              {formatCurrency(client?.balance ?? 0)}
            </Typography>
          </Box>
          <Divider />
          {history.map((item, index) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingTop: 1,
              }}
              key={index.toString()}
            >
              <Typography variant="body2" fontWeight="300">
                {DateTime.fromSeconds(
                  item.created_at.seconds
                ).toFormat("dd/MM/yyyy HH:mm")}
                {item.label === "donation" && " (Doação)"}
                {item.label === "refund" && " (Devolução)"}
              </Typography>

              <Typography
                fontWeight="bold"
                variant="body2"
                color={() => getColor(item.label)}
              >
                {item.label === "credit" && "+"}
                {item.label === "sale" && "-"}
                {item.label === "donation" && "-"}
                {item.label === "refund" && "-"}
                {formatCurrency(parseFloat(item.value))}
              </Typography>
            </Box>
          ))}

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ marginBottom: 1 }}
          onClick={() => setHistoryModal(false)}
        >
          Fechar
        </Button>

      </DialogActions>
    </Dialog>
  )
}

export default HistoryModal;
