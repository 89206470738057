import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	LinearProgress,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
import { Drawer } from "../../../components/Drawer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import api from "../../../services/api";
import { TopBar } from "../../../components/TopBar";
import { Template } from "../../../components/Template";
import { InfoAlert } from "../../../components/InfoAlert";

interface UserMasterProps {
	id: number;
	name: string;
	email: string;
}

const UserMaster = () => {
	const navigation = useNavigate();

	const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
	const [selected, setSelected] = useState<string | number | undefined>();
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const [items, setItems] = useState<UserMasterProps[]>([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(1);
	const [filter, setFilter] = useState("");

	useEffect(() => {
		if (filter !== "") {
			filterItems();
		} else {
			getItems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const getItems = () => {
		setLoading(true);
		api.get("/admin/users/role/1/20?page=" + currentPage)
			.then((res) => {
				setLastPage(res.data.meta.last_page);
				setCurrentPage(res.data.meta.current_page);
				setItems(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	const filterItems = () => {
		setLoading(true);
		api.get("/admin/users/filter/" + filter)
			.then((res) => {
				setLastPage(res.data?.meta?.last_page || 1);
				setCurrentPage(res.data?.meta?.current_page || 1);
				setItems(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(
					err?.response?.status === 401
						? "Você não tem permissão"
						: "Algo de errado ocorreu"
				);
				setLoading(false);
			});
	};

	const deleteItem = () => {
		setShowConfirmationDelete(false);
		if (selected) {
			setLoading(true);
			api.delete(`/admin/users/${selected}`)
				.then(() => {
					setSelected(undefined);
					getItems();
				})
				.catch((err) => {
					setSelected(undefined);
					setError(
						err?.response?.status === 401
							? "Você não tem permissão"
							: "Algo de errado ocorreu"
					);
					setLoading(false);
				});
		}
	};

	return (
		<Template>
			<TopBar title="Usuário Master" />
			<Drawer />
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "#eeeeee", p: 3, width: "100%" }}
			>
				<Toolbar />
				{loading && <LinearProgress className="loader" />}
				<TableContainer component={Paper}>
					<Box sx={{ padding: 3, alignItems: "center" }}>
						<TextField
							id="filter"
							label="Filtrar"
							variant="outlined"
							value={filter}
							onChange={(v) => setFilter(v.target.value)}
							size="small"
							sx={{ width: 300 }}
						/>
						<Button
							variant="contained"
							onClick={() => filterItems()}
							sx={{ marginLeft: 1 }}
							disabled={filter === ""}
						>
							<SearchIcon />
						</Button>
						<Button
							variant="text"
							size="small"
							onClick={() => {
								setFilter("");
								getItems();
							}}
							sx={{ marginLeft: 1 }}
						>
							Limpar filtro
						</Button>
						<Button
							variant="contained"
							onClick={() => navigation("/user-master/form")}
							sx={{ marginLeft: 3, float: "right" }}
						>
							Novo Usuário Master
						</Button>
					</Box>
					<Table sx={{ minWidth: "100%" }}>
						<TableHead>
							<TableRow>
								<TableCell>Nome</TableCell>
								<TableCell>Email</TableCell>
								<TableCell align="center">Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((row) => (
								<TableRow
									key={row.id}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell component="th" scope="row">
										{row.name}
									</TableCell>
									<TableCell component="th" scope="row">
										{row.email}
									</TableCell>
									<TableCell align="center">
										<IconButton
											aria-label="edit"
											onClick={() =>
												navigation(
													`/user-master/edit/${row.id}`
												)
											}
										>
											<EditIcon />
										</IconButton>
										<IconButton
											aria-label="delete"
											onClick={() => {
												setShowConfirmationDelete(true);
												setSelected(row.id);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{items.length === 0 && (
						<Box sx={{ padding: 3 }}>
							<Typography color="GrayText">
								Não foram encontrados itens
							</Typography>
						</Box>
					)}
					<Box sx={{ padding: 2, justifyContent: "flex-end" }}>
						<Pagination
							count={lastPage}
							page={currentPage}
							onChange={(e, v) => setCurrentPage(v)}
						/>
					</Box>
				</TableContainer>
			</Box>
			<Dialog
				open={showConfirmationDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Deseja apagar esse usuário master?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						O usuário perderá o acesso ao sistema
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowConfirmationDelete(false)}>
						Não
					</Button>
					<Button onClick={() => deleteItem()}>Sim</Button>
				</DialogActions>
			</Dialog>

			<InfoAlert
				onClose={() => setSuccess(false)}
				open={success}
				text="Deletado com sucesso"
				type="success"
			/>

			<InfoAlert
				onClose={() => setError("")}
				open={error !== ""}
				text={error}
				type="error"
			/>
		</Template>
	);
};

export default UserMaster;
