import {
	Card,
	CardContent,
	Divider,
	List,
	ListItem,
	ListItemButton,
	Typography,
} from "@mui/material";
import React from "react";
import "./styles.css";

interface ModalPaymentTypeProps {
	onClick: (p: string) => void;
}

const ModalPaymentType: React.FC<ModalPaymentTypeProps> = ({ onClick }) => {
	return (
		<div className="overlay">
			<Card>
				<CardContent>
					<Typography
						sx={{ fontSize: 14 }}
						color="text.secondary"
						gutterBottom
					>
						Escolha a forma de pagamento
					</Typography>
					<Divider />
					<List>
						<ListItem dense>
							<ListItemButton
								onClick={() => onClick("pix")}
								disableGutters
							>
								<Typography fontWeight="bold">PIX</Typography>
							</ListItemButton>
						</ListItem>
						<ListItem dense>
							<ListItemButton
								disableGutters
								onClick={() => onClick("cartao-credito")}
							>
								<Typography fontWeight="bold">
									Cartão de Crédito
								</Typography>
							</ListItemButton>
						</ListItem>
					</List>
				</CardContent>
			</Card>
		</div>
	);
};

export default ModalPaymentType;
